import { useUser } from 'auth.provider';

export type ProgressStatType = {
  color: string;
  label: string;
  labelMeta: string;
  percentage: number;
  text: string;
  total: number;
};

export const useProgressStats = () => {
  const user = useUser();
  const totalTrips = user.totalTrips;
  const countriesVisited = user.countriesVisited;

  let progressStats: { [key: string]: ProgressStatType } = {};
  if (!totalTrips || !countriesVisited) return progressStats;

  let regionsCount = 0;
  let totalCountriesCount = 0;

  // Build progress stats structure
  progressStats = {
    trips: {
      label: 'Trips',
      labelMeta: 'Tracked',
      total: totalTrips,
      text: `${totalTrips}`,
      color: 'purple',
      percentage: 100,
    },
    countries: { label: 'Countries', labelMeta: '', total: 195, text: '', color: 'coral', percentage: 0 },
    regions: { label: 'Continents', labelMeta: '', total: 7, text: '', color: 'green', percentage: 0 },
    AF: { label: 'Africa', labelMeta: '', total: 14, text: '', color: 'yellow', percentage: 0 },
    AS: { label: 'Asia', labelMeta: '', total: 48, text: '', color: 'skyblue', percentage: 0 },
    EU: { label: 'Europe', labelMeta: '', total: 54, text: '', color: 'pink', percentage: 0 },
    OC: { label: 'Oceania', labelMeta: '', total: 44, text: '', color: 'coral', percentage: 0 },
    NA: { label: 'North America', labelMeta: '', total: 23, text: '', color: 'green', percentage: 0 },
    SA: { label: 'South America', labelMeta: '', total: 12, text: '', color: 'purple', percentage: 0 },
  };

  const setPercentage = (key: string, count: number, isContinent = false) => {
    progressStats[key].labelMeta = `${count} / ${progressStats[key].total}`;
    const percentage = Math.round((count / progressStats[key].total) * 100);
    progressStats[key].percentage = percentage;
    progressStats[key].text = isContinent ? `0${count}` : `${percentage.toFixed(0)}%`;
  };

  const regions = ['AF', 'AS', 'EU', 'OC', 'NA', 'SA'];
  regions.forEach((key) => {
    let count = 0;
    if (countriesVisited && key in countriesVisited) {
      count = Object.keys(countriesVisited[key]).length;
      regionsCount++;
      totalCountriesCount += count;
    }
    setPercentage(key, count);
  });

  setPercentage('countries', totalCountriesCount);
  setPercentage('regions', regionsCount, true);

  return progressStats;
};
