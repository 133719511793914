import firebase from 'firebase/app';
import 'firebase/functions';

export type StatsByCountry = {
  [country: string]: {
    intervals: {
      id: string;
      days: number;
      startDate: firebase.firestore.Timestamp;
      endDate: firebase.firestore.Timestamp;
    }[];
    inDaysByYear: {
      [year: string]: number;
    };
    outDaysByYear: {
      [year: string]: number;
    };
  };
};

export class StatsService {
  constructor(private store: firebase.firestore.Firestore) {}

  public fetchByCountry = async (userId: string, countryId: string) => {
    const snapshot = await this.store.collection('users').doc(userId).collection('statsByCountry').doc(countryId).get();
    return snapshot.data();
  };

  public generate = async (userId: string) => {
    const generateStats = firebase.functions().httpsCallable('generateStats');
    const { data } = await generateStats({ userId });
    return data;
  };
}
