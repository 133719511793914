import React, { FC, MouseEventHandler } from 'react';
import 'twin.macro';

import { Logo } from 'pages/shared-components/logo';
import { ProgressBar } from 'pages/shared-components/progress-bar';

import LogoPatternImage from 'assets/images/logo-pattern.png';
import { ReactComponent as GoogleIcon } from 'assets/images/google-icon.svg';

const styleBackground = { backgroundImage: `url(${LogoPatternImage})`, backgroundRepeat: 'repeat' };

export const AuthLayout: FC<{ title: string; isLoading?: boolean }> = ({ title, isLoading = false, children }) => {
  return (
    <div tw="font-body tracking-wide text-gray-700 bg-dark min-h-screen" style={styleBackground}>
      <div tw="relative block py-10 md:py-20">
        <div className="container" tw="max-w-md mx-auto px-4">
          <div tw="w-full bg-white rounded shadow relative overflow-hidden mb-6">
            <ProgressBar isLoading={isLoading} />
            <div tw="flex-auto p-6 lg:p-8">
              <div tw="flex justify-center">
                <Logo />
              </div>
              <hr tw="my-6" />
              <h4 tw="font-display text-lg text-center mb-6">{title}</h4>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GoogleButton: FC<{ onClick: MouseEventHandler }> = ({ onClick }) => (
  <div
    tw="w-full flex items-center justify-center border border-gray-400 px-6 py-3 rounded cursor-pointer mb-6"
    onClick={onClick}
  >
    <GoogleIcon tw="w-5 mr-3" />
    Continue with Google
  </div>
);

export const Divider = () => (
  <div tw="text-center pt-2 mb-8">
    <span tw="relative font-display text-sm text-gray-500 bg-white tracking-wider px-3">OR</span>
    <div tw="border-b border-dashed border-gray-400 -mt-3"></div>
  </div>
);
