import SweetAlert from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { colors } from 'pages/shared-components/colors';

const sweetAlert = withReactContent(SweetAlert);

type NotifyConfirm = {
  confirmButtonText?: string;
  icon?: string;
  isDestructive?: boolean;
  text: string;
  title: string;
};

export const Notify = {
  confirm: ({ icon = 'warning', text, title, confirmButtonText = 'Yes', isDestructive = false }: NotifyConfirm) => {
    return sweetAlert.fire({
      cancelButtonColor: colors.gray500,
      cancelButtonText: 'No',
      confirmButtonColor: isDestructive ? colors.red700 : colors.primary,
      confirmButtonText,
      icon,
      reverseButtons: true,
      showCancelButton: true,
      showCloseButton: true,
      text,
      title,
    });
  },
};
