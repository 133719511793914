import React, { FC, ReactNode } from 'react';
import 'twin.macro';

import { Banner } from './banner';
import { Footer } from './footer';
import { Header } from './header';
import { QuickSignup } from './quick-signup';

import LogoPatternImage from 'assets/images/logo-pattern.png';

type MarketingLayout = {
  title: string;
  subtitle: string | ReactNode;
  isTallBanner?: boolean;
};

const styleBackground = { backgroundImage: `url(${LogoPatternImage})`, backgroundRepeat: 'repeat' };

export const MarketingLayout: FC<MarketingLayout> = ({ title, subtitle, isTallBanner = false, children }) => {
  return (
    <div tw="font-body tracking-wide text-gray-700">
      <Header />
      <Banner title={title} subtitle={subtitle} isTall={isTallBanner} />
      {children}
      <section tw="relative block py-20 bg-dark" style={styleBackground}>
        <div className="container" tw="mx-auto px-4">
          <div tw="flex flex-wrap justify-center">
            <div tw="w-full max-w-md px-4">
              <div tw="bg-white rounded shadow mb-6">
                <QuickSignup />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
