import React from 'react';
import 'twin.macro';

import { Illustration } from './illustration';
import { MarketingLayout } from './marketing-layout';

export const AboutPage = () => {
  return (
    <MarketingLayout title="Welcome to ExpaTrack" subtitle="If you have questions, please reach out to us :)">
      <section tw="py-20 bg-gray-200">
        <div tw="mx-auto max-w-2xl px-4">
          <div tw="flex flex-wrap justify-center">
            <div tw="px-4">
              <div tw="bg-white rounded border border-gray-400 mb-6">
                <div tw="flex-auto p-6 lg:p-12 text-center text-base md:text-xl leading-loose">
                  <div>
                    We started ExpaTrack because we needed something more than a collection of notes &amp; spreadsheets
                    to track our travels.
                  </div>
                  <div tw="mt-6 md:mt-10">
                    If you have any questions, please{' '}
                    <a href="mailto:support@expatrack.com" target="_blank" rel="noopener noreferrer" tw="text-primary">
                      email us
                    </a>{' '}
                    and we will do our best to respond to you promptly.
                  </div>
                  <div tw="mt-6 md:mt-10">
                    Connect with us via{' '}
                    <a href="https://twitter.com/expatrack" target="_blank" rel="noopener noreferrer" tw="text-primary">
                      Twitter
                    </a>
                    ,{' '}
                    <a
                      href="https://facebook.com/expatrack"
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="text-primary"
                    >
                      Facebook
                    </a>{' '}
                    or{' '}
                    <a
                      href="https://instagram.com/expatrack"
                      target="_blank"
                      rel="noopener noreferrer"
                      tw="text-primary"
                    >
                      Instagram
                    </a>
                    .
                  </div>
                </div>
                <Illustration icon="travel" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </MarketingLayout>
  );
};
