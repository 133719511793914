import React from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { Illustration } from './illustration';
import { IllustrationCard } from './illustration-card';
import { MarketingLayout } from './marketing-layout';

import AppAtAirportImage from 'assets/images/app-at-airport.jpg';
import BirthdayPartyImage from 'assets/images/birthday-party.jpg';

const styleBottomRight = { bottom: '-10px', right: '-10px' };

export const HomePage = () => {
  return (
    <MarketingLayout
      title="Track memories that matter"
      subtitle={
        <div>
          Tailor made for you, the modern <span tw="border-b border-green pb-1">travelers</span> :)
        </div>
      }
      isTallBanner={true}
    >
      <section tw="pb-20 bg-gray-200 -mt-32">
        <div className="container" tw="mx-auto px-4">
          <div tw="flex flex-wrap">
            <IllustrationCard title="frequent flyers" icon="luggage" color="green" isOffset={true}>
              For frequent travelers with amazing stories to share &amp; <span tw="font-bold">memories</span> to track,
              all within one app.
            </IllustrationCard>
            <IllustrationCard title="students &amp; workers" icon="backpack" color="yellow" isOffset={false}>
              For people who live overseas and need to track their travel history for <span tw="font-bold">visa</span>{' '}
              &amp; immigration purposes.
            </IllustrationCard>
            <IllustrationCard title="business travelers" icon="suitcase" color="coral" isOffset={true}>
              For business travelers who need to keep track of their travel history to meet their{' '}
              <span tw="font-bold">tax</span> obligations.
            </IllustrationCard>
          </div>

          <div tw="flex flex-wrap items-center mt-20 md:mt-32">
            <div tw="w-full md:w-1/2 px-4 mx-auto mb-20 md:mb-0">
              <div tw="w-20 h-20 mb-4">
                <Illustration icon="ticket" />
              </div>
              <h3 tw="font-display text-2xl mb-3">Remember your trips forever</h3>
              <p tw="text-lg leading-relaxed mt-4 mb-4">
                Your travel history cataloged with your hashtags, travel statistics and all your photos.
                <sup tw="text-gray-600">*</sup>
              </p>
              <p tw="text-lg leading-relaxed mb-10">Stored securely with privacy in mind. Your data belongs to you.</p>
              <Link to="/signup" tw="inline-block bg-primary text-white rounded py-3 px-6">
                Signup for free
              </Link>
              <div tw="text-xs text-gray-600 italic mt-8">
                <sup>*</sup>Coming soon :)
              </div>
            </div>

            <div tw="w-full md:w-1/2 lg:w-1/3 px-4 mx-auto mb-6">
              <div tw="relative flex flex-col">
                <div tw="relative bg-white border-2 border-brand rounded-lg overflow-hidden z-10">
                  <img src={AppAtAirportImage} tw="w-full align-middle rounded-t pointer-events-none" alt="" />
                  <blockquote tw="relative p-8">
                    <h4 tw="font-display text-xl mb-2">Seamless experience</h4>
                    <p tw="text-base">Easily add your trips without any hassle. Even when you are on the go.</p>
                  </blockquote>
                </div>
                <div
                  tw="absolute w-full h-full flex-auto bg-purple border-2 border-brand rounded-lg"
                  style={styleBottomRight}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section tw="relative py-20">
        <div className="container" tw="mx-auto px-4">
          <div tw="items-center flex flex-wrap">
            <div tw="w-full md:w-1/2 lg:w-1/3 ml-auto mr-auto px-4">
              <img src={BirthdayPartyImage} tw="w-full align-middle rounded pointer-events-none" alt="" />
            </div>
            <div tw="w-full md:w-1/2 ml-auto mr-auto px-4">
              <div tw="mt-20 md:mt-0">
                <div tw="w-20 mb-6">
                  <Illustration icon="cake" />
                </div>
                <h3 tw="font-display text-2xl mb-3">Remember special occasions</h3>
                <p tw="mt-4 text-lg leading-relaxed">
                  Never forget a birthday, anniversary or any special occasion ever again with upcoming features like
                  customised reminders and calendar integrations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MarketingLayout>
  );
};
