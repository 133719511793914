import React from 'react';
import Toggle from 'react-toggle';
import 'twin.macro';

import { SettingsLayout } from './settings.layout';
import { ReactComponent as NotificationIcon } from 'assets/icons/alarm-bell.svg';

const clsNotificationSetting = 'flex items-center justify-between border-b border-dashed pb-6 mb-6';

export const NotificationSettingsPage = () => {
  return (
    <SettingsLayout
      color="yellow"
      icon={<NotificationIcon />}
      text="Choose type of notifications you want to receive."
      title="Notification preferences"
    >
      <div tw="bg-white rounded border border-gray-400 p-8 md:p-12">
        <div tw="font-semibold border-b border-gray-200 pb-2 mb-8">Email preferences</div>
        <div className={clsNotificationSetting}>
          <div tw="pr-10">
            <div tw="mb-1">Product intro, tips and feedback</div>
            <div tw="text-gray-500">How to best use ExpaTrack and feedback emails so we can improve :)</div>
          </div>
          <Toggle defaultChecked={true} />
        </div>
        <div className={clsNotificationSetting}>
          <div tw="pr-10">
            <div tw="mb-1">Company emails</div>
            <div tw="text-gray-500">News about ExpaTrack, our latest features and product releases.</div>
          </div>
          <Toggle defaultChecked={true} />
        </div>
        <div className={clsNotificationSetting}>
          <div tw="pr-10">
            <div tw="mb-1">Account emails</div>
            <div tw="text-gray-500">Emails about important things like your account, security and privacy.</div>
          </div>
          <Toggle defaultChecked={true} disabled={true} />
        </div>
        <div tw="text-right">
          <button tw="w-full md:w-auto bg-primary text-white px-6 py-3 rounded cursor-pointer">Save</button>
        </div>
      </div>
    </SettingsLayout>
  );
};
