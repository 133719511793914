import React, { FC } from 'react';
import 'twin.macro';

const styleHeight3 = { height: '3px' };

type ProgressBar = { isLoading: boolean; isBottom?: boolean; color?: string };
export const ProgressBar: FC<ProgressBar> = ({ isLoading, isBottom = false, color = 'green' }) => (
  <div
    tw="w-full absolute overflow-hidden bg-opacity-25 transition-all"
    className={`${isBottom ? 'bottom-0' : 'top-0'} ${isLoading ? 'opacity-100' : 'opacity-0'} bg-${color}`}
    style={styleHeight3}
  >
    <div tw="w-full h-full" className={`progress-animation bg-${color}`}></div>
  </div>
);
