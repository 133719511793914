import React, { FormEvent, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import useFormFields from '@usereact/use-form-fields';
import 'twin.macro';

import { useAuth, useUser } from 'auth.provider';
import { Country } from 'services/country.service';
import { LocationAutocomplete } from 'pages/shared-components/location-autocomplete';

import { AuthLayout } from './login.layout';

export const ProfilePage = () => {
  const user = useUser();
  const { createProfile, logout } = useAuth();

  const { values, fields } = useFormFields({ name: '' });
  const [profileError, setProfileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [country, setCountry] = useState<Country | undefined>(undefined);
  const validateForm = () => values.name.length && country;

  const handleProfileUpdate = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      if (!country) return;

      setIsLoading(true);
      setProfileError('');

      createProfile(user, { name: values.name, country }).catch((e) => {
        setIsLoading(false);
        setProfileError(e.message);
      });
    },
    [user, country, values.name, createProfile],
  );

  if (!user.isLoggedIn) return <Redirect to="/login" />;
  else if (user.isLoggedIn && user.hasProfile) return <Redirect to="/trips" />;

  return (
    <AuthLayout title="Profile details" isLoading={isLoading}>
      <div tw="bg-blue-100 border border-blue-400 text-blue-600 text-center text-sm px-4 py-3 rounded mb-6">
        You are almost done! Please enter profile details to continue:
      </div>
      <form onSubmit={handleProfileUpdate}>
        <fieldset tw="mb-6">
          <label tw="block mb-1" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Enter full name"
            {...fields.name}
          />
        </fieldset>
        <fieldset tw="mb-6">
          <LocationAutocomplete uid="country" text="Home country" onSelect={setCountry} />
        </fieldset>
        <fieldset tw="mb-6">
          <input
            tw="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:(bg-gray-500 cursor-not-allowed)"
            disabled={!validateForm()}
            type="submit"
            value="Save and continue"
          />
          <div
            tw="bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4"
            className={`${profileError ? '' : 'hidden'}`}
          >
            {profileError}
          </div>
        </fieldset>
      </form>
      <div tw="text-center">
        <span tw="text-primary cursor-pointer" onClick={logout}>
          Logout
        </span>
      </div>
    </AuthLayout>
  );
};
