import React, { FormEvent, useState, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import 'twin.macro';

import useFormFields from '@usereact/use-form-fields';
import { useQuery } from 'hooks/use-query';

import { useAuth, useUser } from 'auth.provider';
import { AuthLayout, Divider, GoogleButton } from './login.layout';

export const SignupPage = () => {
  const user = useUser();
  const { signup, signInWithGoogle } = useAuth();

  const query = useQuery();
  const email = query.email ? (query.email as string) : '';

  const { values: credentials, fields } = useFormFields({ email, password: '' });
  const validateForm = () => credentials.email.length && credentials.password.length;
  const [signupError, setSignupError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const errorCallback = useCallback((error) => {
    setIsLoading(false);
    setSignupError(error.message);
  }, []);

  const handleSignup = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      setSignupError('');

      signup(credentials).catch(errorCallback);
    },
    [credentials, signup, errorCallback],
  );

  const handleSignInWithGoogle = useCallback(() => {
    setIsLoading(true);
    setSignupError('');

    signInWithGoogle().catch(errorCallback);
  }, [signInWithGoogle, errorCallback]);

  if (user.isLoggedIn) return <Redirect to="/trips" />;

  return (
    <AuthLayout title="Signup - quick &amp; easy" isLoading={isLoading}>
      <GoogleButton onClick={handleSignInWithGoogle} />
      <Divider />
      <form onSubmit={handleSignup}>
        <fieldset tw="mb-6">
          <label tw="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Email"
            {...fields.email}
          />
        </fieldset>
        <fieldset tw="mb-6">
          <label tw="block mb-1" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Password"
            {...fields.password}
          />
        </fieldset>
        <fieldset tw="mb-6">
          <input
            tw="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:(bg-gray-500 cursor-not-allowed)"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? 'Please wait...' : 'Signup for free'}
          />
          <div
            tw="bg-red-100 border border-red-400 text-red-600 text-center text-sm px-4 py-3 rounded mt-4"
            className={`${signupError ? '' : 'hidden'}`}
          >
            {signupError}
          </div>
        </fieldset>
      </form>
      <div tw="text-center">
        Have an account?{' '}
        <Link to="/login" tw="text-primary">
          Login
        </Link>
      </div>
    </AuthLayout>
  );
};
