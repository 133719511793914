import React, { FC, useMemo } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import 'twin.macro';

import { Sidebar } from './sidebar';
import { useMediaQuery } from 'hooks/use-media-query';
import { ProgressBar } from 'pages/shared-components/progress-bar';

const styleMinHeight1 = { minHeight: '1px' };

type AppLayout = { title: string; isLoading?: boolean; onScrollEnd?: () => void };
export const AppLayout: FC<AppLayout> = ({
  title,
  isLoading = false,
  children,
  onScrollEnd = () => {
    return;
  },
}) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const styleContent = useMemo(() => ({ flex: isMobile ? '0 0 100vw' : 'auto' }), [isMobile]);
  const scrollRef: any = useBottomScrollListener(onScrollEnd);

  return (
    <div tw="font-body text-gray-700">
      <div tw="absolute left-0 top-0 right-0 bottom-0 flex overflow-hidden">
        <Sidebar />
        <div tw="relative flex flex-1 flex-col w-full bg-gray-200 min-h-screen w-screen" style={styleContent}>
          <PageTitle title={title} isLoading={isLoading} />
          <div tw="flex flex-auto relative overflow-y-auto" style={styleMinHeight1} ref={scrollRef}>
            <div className="container" tw="mx-auto">
              <div tw="my-8 md:my-12 px-8">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styleMinHeight72 = { minHeight: '72px' };
const PageTitle: FC<{ title: string; isLoading: boolean }> = ({ title, isLoading }) => (
  <div
    tw="flex items-center justify-center relative bg-white border-b border-gray-400 px-8 z-10"
    style={styleMinHeight72}
  >
    <div tw="text-sm text-gray-500 font-bold uppercase tracking-widest">{isLoading ? 'Loading...' : title}</div>
    <ProgressBar isLoading={isLoading} isBottom={true} />
  </div>
);
