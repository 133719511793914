import React, { FC } from 'react';
import 'twin.macro';

import InviteArt from 'assets/illustrations/invite.svg';

type Illustration = { icon: string };

export const Illustration: FC<Illustration> = ({ icon }) => {
  const iconMap: Record<string, string> = {
    invite: InviteArt,
  };

  return (
    <div>
      <img src={iconMap[icon]} alt="" tw="w-full pointer-events-none" />
    </div>
  );
};
