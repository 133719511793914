// CSS classes to exclude from PurgeCSS
const colorsClassNames = [
  'bg-coral',
  'bg-green',
  'bg-pink',
  'bg-purple',
  'bg-skyblue',
  'bg-yellow',
  'border-coral',
  'border-green',
  'border-pink',
  'border-purple',
  'border-skyblue',
  'border-yellow',
  'border-t-1',
  'border-t-4',
  'text-coral',
  'text-green',
  'text-pink',
  'text-purple',
  'text-skyblue',
  'text-yellow',
];

// Colors used in components
const colors = {
  gray400: '#cbd5e0',
  gray500: '#a0aec0',
  lavender: '#ebd9ff',
  red700: '#c53030',
  primary: '#5e549a',
  white: '#ffffff',
};

export { colorsClassNames, colors };
