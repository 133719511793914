import { useState, useEffect, useCallback } from 'react';
import { Moment } from 'moment';
import useFormFields from '@usereact/use-form-fields';
import { useDatastore, useUser } from 'auth.provider';
import { Occasion as OccasionType, OccasionService } from 'services/occasion.service';

export type Occasion = OccasionType;

export const useAddOccasion = () => {
  const store = useDatastore();
  const user = useUser();
  const service = new OccasionService(store);

  const { values, fields, setValue } = useFormFields({ title: '', type: 'undefined' });
  const [date, _setDate] = useState<Moment | null>(null);

  const [isBirthdayType, setIsBirthdayType] = useState(false);
  const [isAnniversaryType, setIsAnniversaryType] = useState(false);
  const [isOtherType, setIsOtherType] = useState(false);

  const setType = useCallback(
    (value: string) => {
      setValue('type', value);
    },
    [setValue],
  );

  const resetForm = () => {
    setValue('title', '');
    setValue('type', 'undefined');

    setIsBirthdayType(false);
    setIsAnniversaryType(false);
    setIsOtherType(false);
  };

  const setDate = useCallback(
    (value: Moment | null) => {
      return _setDate(value);
    },
    [_setDate],
  );

  useEffect(() => {
    if (values.type === 'undefined') return;
    setType(values.type.toLowerCase());

    setIsBirthdayType(values.type === 'birthday');
    setIsAnniversaryType(values.type === 'anniversary');
    setIsOtherType(values.type !== 'birthday' && values.type !== 'anniversary');
  }, [values.type, setType]);

  const isValid = () => {
    return !!values.title && values.type !== 'undefined' && date?.isValid();
  };

  const save = async () => {
    if (!isValid()) return new Error('This is a programming error.');
    const occasion = { title: values.title, type: values.type, date };
    return await service.add(user.id, occasion);
  };

  const deleteById = async (occasionId: string) => {
    return await service.remove(user.id, occasionId);
  };

  return {
    date,
    fields,
    isAnniversaryType,
    isBirthdayType,
    isOtherType,
    isValid,
    deleteById,
    resetForm,
    save,
    setDate,
    setType,
  };
};
