import React, { FC, useMemo, useState, useCallback, MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';
import 'twin.macro';

import { useMediaQuery } from 'hooks/use-media-query';

import { Logo } from 'pages/shared-components/logo';
import LogoPatternImage from 'assets/images/logo-pattern.png';
import { ReactComponent as OpenSidebarIcon } from 'assets/icons/sidebar-open.svg';
import { ReactComponent as CloseSidebarIcon } from 'assets/icons/sidebar-close.svg';
import { Illustration } from './illustration';

import { ReactComponent as TripIcon } from 'assets/icons/plane-take-off.svg';
import { ReactComponent as StatsIcon } from 'assets/icons/calendar-search-1.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/cog.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/power-button.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/alert-circle.svg';
import { ReactComponent as MemoriesIcon } from 'assets/icons/picture-polaroid.svg';
import { ReactComponent as BirthdayIcon } from 'assets/icons/cake-birthday.svg';
import { ReactComponent as LockedIcon } from 'assets/icons/lock-8.svg';
import { ReactComponent as HashtagIcon } from 'assets/icons/hash.svg';
import { useAuth, useUser } from 'auth.provider';

const styleHeight72 = { height: '72px' };
const styleMaxWidth110 = { maxWidth: '110px' };

export const Sidebar = () => {
  const { logout } = useAuth();
  const user = useUser();

  const isMobile = useMediaQuery('(max-width: 640px)');
  const [isSidebarVisible, toggleSidebar] = useState(!isMobile);
  const toggle = useCallback(() => toggleSidebar(!isSidebarVisible), [isSidebarVisible, toggleSidebar]);

  const sidebarStyles = useMemo(
    () => ({
      backgroundImage: `url(${LogoPatternImage})`,
      backgroundRepeat: 'repeat',
      marginLeft: isSidebarVisible ? 0 : '-240px',
      minWidth: '240px',
      transition: 'margin-left 0.4s ease-in-out',
    }),
    [isSidebarVisible],
  );

  return (
    <>
      <div tw="absolute left-0 z-20" className={`${isSidebarVisible ? 'hidden' : 'block'}`}>
        <div tw="inline-flex items-center mx-4" style={styleHeight72}>
          <OpenSidebar onClick={toggle} />
        </div>
      </div>
      <div tw="flex flex-col max-h-screen bg-dark relative overflow-hidden" style={sidebarStyles}>
        <div tw="flex items-center justify-between mx-6" style={styleHeight72}>
          <div style={styleMaxWidth110}>
            <Logo useLight={true} />
          </div>
          <CloseSidebar onClick={toggle} />
        </div>

        <SidebarLinkGroup>
          <SidebarLabel text="Manage" />
          <SidebarLink text="Trips" to="/trips" icon={<TripIcon />} />
          <SidebarLink text="Occasions" to="/occasions" icon={<BirthdayIcon />} />
          <SidebarLink text="Stats" to="/stats" icon={<StatsIcon />} />
          <SidebarLink text="Memories" to="/memories" icon={<MemoriesIcon />} isLocked={true} />
        </SidebarLinkGroup>

        <ScrollArea>
          {user.tags && (
            <SidebarLinkGroup>
              <SidebarLabel text="Tags" />
              {user.tags.map((tag) => (
                <HashtagLink key={tag} tag={tag} />
              ))}
            </SidebarLinkGroup>
          )}

          <SidebarLinkGroup>
            <SidebarLabel text="Account" />
            <SidebarLink text="Settings" to="/settings" icon={<SettingsIcon />} />
            <SidebarLink text="Logout" to="/" icon={<LogoutIcon />} onClick={logout} />
          </SidebarLinkGroup>

          <SidebarLinkGroup>
            <a
              href="mailto:support@expatrack.com?subject=Report issue"
              tw="flex px-6 py-2 text-sm tracking-wide mb-3 last:mb-0 text-coral"
            >
              <div tw="w-5 mr-4">
                <ReportIcon />
              </div>
              Report issue
            </a>
          </SidebarLinkGroup>

          <Invite />
        </ScrollArea>
      </div>
    </>
  );
};

const CloseSidebar: FC<{ onClick: MouseEventHandler }> = ({ onClick }) => (
  <button tw="text-white opacity-75 hover:opacity-100 cursor-pointer" onClick={onClick}>
    <CloseSidebarIcon height="16" tw="fill-current" />
  </button>
);

const OpenSidebar: FC<{ onClick: MouseEventHandler }> = ({ onClick }) => (
  <button tw="cursor-pointer" onClick={onClick}>
    <OpenSidebarIcon height="16" tw="fill-current" />
  </button>
);

const SidebarLabel: FC<{ text: string }> = ({ text }) => <div tw="text-xs text-white mx-6 mb-3 opacity-50">{text}</div>;

type SidebarLink = {
  to: string;
  text: string;
  isActive?: boolean;
  isLocked?: boolean;
  icon: React.ReactNode;
  onClick?: MouseEventHandler;
};

const styleMarginLeftAuto = { marginLeft: 'auto' };
const SidebarLink: FC<SidebarLink> = ({ to, text, isLocked = false, icon, onClick, isActive }) => (
  <NavLink
    to={to}
    activeClassName="link--active text-yellow bg-white bg-opacity-10 rounded-l-full"
    className={`link text-white ${isLocked ? 'pointer-events-none' : ''}`}
    tw="flex px-6 py-2 text-sm tracking-wide mb-3 last:mb-0"
    {...((isActive === false || onClick) && { isActive: () => false })}
    {...(onClick && { onClick })}
  >
    <div className="link__icon" tw="w-5 mr-4">
      {icon}
    </div>
    <span className={`${isLocked ? 'opacity-50' : ''}`}>{text}</span>
    {isLocked ? (
      <div className="link__icon" tw="w-4 h-4" style={styleMarginLeftAuto}>
        <LockedIcon />
      </div>
    ) : (
      ''
    )}
  </NavLink>
);

const HashtagLink: FC<{ tag: string }> = ({ tag }) => (
  <NavLink
    to={`/trips?tag=${tag}`}
    tw="flex items-center text-white text-sm leading-none opacity-75 hover:opacity-100 px-6 py-2 my-1 last:mb-0"
  >
    <HashtagIcon tw="w-3 mr-2" />
    <div>{tag}</div>
  </NavLink>
);

const SidebarLinkGroup: FC = ({ children }) => (
  <div tw="border-b border-white border-opacity-10 pl-4 py-6">{children}</div>
);

const styleMinHeight1 = { minHeight: '1px' };
const stylePaddingBottom240 = { paddingBottom: '240px' };
const ScrollArea: FC = ({ children }) => (
  <div tw="flex-auto relative overflow-hidden" style={styleMinHeight1}>
    <div tw="absolute left-0 right-0 bottom-0 top-0 overflow-y-auto" style={styleMinHeight1}>
      <div tw="relative min-h-full" style={stylePaddingBottom240}>
        {children}
      </div>
    </div>
  </div>
);

const Invite = () => (
  <div tw="absolute left-0 right-0 bottom-0 text-white text-xs text-center">
    <div tw="flex flex-col items-center justify-center m-6">
      <div tw="w-20 mb-4">
        <Illustration icon="invite" />
      </div>
      <div tw="mb-4 opacity-75">Do you like ExpaTrack? Invite your family &amp; friends :)</div>
      <a
        href="mailto:?body=Hey%2C%20you%27ve%20been%20invited%20to%20join%20ExpaTrack%20-%20https%3A%2F%2Fexpatrack.com&subject=You%27ve%20been%20invited%20to%20ExpaTrack"
        rel="noopener noreferrer"
        target="_blank"
        tw="border border-white border-opacity-50 rounded px-4 py-1"
      >
        Invite
      </a>
    </div>
  </div>
);
