import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { AppLayout } from '../app-layout';
import { SettingsCard } from './settings-card';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-thick-dot-left.svg';

const styleTop3 = { top: '3rem' };

type SettingsLayout = {
  color: string;
  icon: React.ReactNode;
  text: string;
  title: string;
};
export const SettingsLayout: FC<SettingsLayout> = ({ color, icon, text, title, children }) => {
  return (
    <AppLayout title="Settings">
      <div tw="flex flex-wrap -mx-4">
        <div tw="w-full lg:w-1/3 px-4 mb-8">
          <div tw="sticky" style={styleTop3}>
            <div tw="mb-4">
              <Link to="/settings" tw="inline-flex items-center">
                <BackIcon tw="w-4 mr-2" />
                <div tw="text-gray-600 font-light">Back to settings</div>
              </Link>
            </div>
            <SettingsCard color={color} icon={icon} text={text} title={title} />
          </div>
        </div>
        <div tw="w-full lg:w-2/3 px-4 lg:pt-10 mb-8">{children}</div>
      </div>
    </AppLayout>
  );
};
