import React, { useState, useCallback, FormEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'twin.macro';

import { ReactComponent as BackIcon } from 'assets/icons/arrow-thick-dot-left.svg';

import TicketArt from 'assets/illustrations/ticket.svg';

import { DateRangePicker } from 'pages/shared-components/date-range-picker';
import { LocationAutocomplete } from 'pages/shared-components/location-autocomplete';

import { AppLayout } from '../app-layout';
import { TripTags } from './trip-tags';
import { WorldMap } from './world-map';

import { useAddTrip } from './use-add-trip';

const maxWidth400 = { maxWidth: '400px' };

export const AddTripPage = () => {
  const trip = useAddTrip();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      setIsLoading(true);
      trip
        .save()
        .then(() => {
          setIsLoading(false);
          history.push('/trips');
        })
        .catch((error) => {
          setIsLoading(false);
          console.warn(error);
        });
    },
    [trip, history, setIsLoading],
  );

  return (
    <AppLayout title="Add trip">
      <div tw="mb-4">
        <Link to="/trips" tw="inline-flex items-center">
          <BackIcon tw="w-4 mr-2" />
          <div tw="text-gray-600 font-light">Back to trips</div>
        </Link>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div tw="flex flex-row justify-center xl:justify-start">
          <div tw="bg-white rounded border border-gray-400 w-full p-8" style={maxWidth400}>
            <div tw="flex items-center justify-between">
              <div tw="font-display text-xl">Trip details</div>
              <img src={TicketArt} alt="" tw="h-12 pointer-events-none" />
            </div>

            <hr tw="my-8" />

            <div tw="relative mb-6">
              <LocationAutocomplete
                uid="location"
                text="Country"
                initialValue={trip.data?.country?.name}
                onSelect={trip.changeCountry}
              />
            </div>

            <div tw="relative mb-6">
              <label tw="block capitalize mb-1">Dates</label>
              <DateRangePicker
                initialStartDate={trip.data.startDate}
                initialEndDate={trip.data.endDate}
                onChange={trip.changeDates}
              />
            </div>

            <div tw="relative mb-8">
              <label tw="block capitalize mb-1">Tags</label>
              <TripTags tags={trip.data.tags} onChange={trip.changeTags} />
            </div>

            <input
              tw="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:(bg-gray-500 cursor-not-allowed)"
              disabled={!trip.isValid() || isLoading}
              type="submit"
              value={isLoading ? 'Please wait...' : 'Add'}
            />
          </div>

          <div tw="hidden lg:flex relative w-full pl-10">
            <WorldMap countryCode={trip.data?.country?.code} onSelect={trip.changeCountry} />
          </div>
        </div>
      </form>
    </AppLayout>
  );
};
