import React, { FormEvent, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import 'twin.macro';

import { Notify } from 'services/notify';

import { ReactComponent as SecurityIcon } from 'assets/icons/lock-8.svg';
import { SettingsLayout } from './settings.layout';
import { useUser, useAuth } from 'auth.provider';
import useFormFields from '@usereact/use-form-fields';

export const SecuritySettingsPage = () => {
  const history = useHistory();
  const user = useUser();
  const { updatePassword, deleteUser } = useAuth();
  const { values, fields } = useFormFields({ currentPassword: '', newPassword: '', verifyNewPassword: '' });
  const [alert, setAlert] = useState({ isSuccess: false, message: '' });

  const handleDeleteUser = useCallback(() => {
    // TODO Fix password
    Notify.confirm({
      confirmButtonText: 'Yes, delete',
      isDestructive: true,
      text: 'Are you sure? This action cannot be undone.',
      title: 'Delete account',
    }).then(({ isDismissed }) => {
      if (isDismissed) return;
      deleteUser({ email: user.email, password: '' })
        .then(() => history.push('/'))
        .catch((e) => {
          console.log(e.message);
        });
    });
  }, [user.email, history, deleteUser]);

  const handlePasswordUpdate = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setAlert({ isSuccess: false, message: '' });

      updatePassword({
        email: user.email,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
        .then(() => setAlert({ isSuccess: true, message: 'Password changed successfully :)' }))
        .catch((e) => setAlert({ isSuccess: false, message: e.message }));
    },
    [user, values, updatePassword],
  );

  return (
    <SettingsLayout
      color="coral"
      icon={<SecurityIcon />}
      text="Change your password or permanently delete your account."
      title="Privacy &amp; security"
    >
      <div tw="bg-white rounded border border-gray-400 p-8 md:p-12 mb-6">
        <div tw="font-semibold border-b border-gray-200 pb-2 mb-6">Change password</div>
        <form tw="mx-auto max-w-full lg:max-w-md" onSubmit={handlePasswordUpdate}>
          <fieldset tw="mb-6">
            <label tw="block mb-1" htmlFor="current-password">
              Current password
            </label>
            <input
              id="current-password"
              type="password"
              tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Enter current password"
              {...fields.currentPassword}
            />
          </fieldset>
          <fieldset tw="mb-6">
            <label tw="block mb-1" htmlFor="new-password">
              New password
            </label>
            <input
              id="new-password"
              type="password"
              tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Enter new password"
              {...fields.newPassword}
            />
          </fieldset>
          <fieldset tw="mb-6">
            <label tw="block mb-1" htmlFor="verify-new-password">
              Verify new password
            </label>
            <input
              id="verify-new-password"
              type="password"
              tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Enter new password again"
              {...fields.verifyNewPassword}
            />
          </fieldset>
          <fieldset tw="text-right">
            <input
              tw="w-full md:w-auto bg-primary text-white px-6 py-3 rounded cursor-pointer"
              type="submit"
              disabled={false}
              value="Change"
            />
          </fieldset>
          <div
            tw="text-center text-sm px-4 py-3 rounded mt-4"
            className={`${alert.message.length > 0 ? '' : 'hidden'} ${
              alert.isSuccess
                ? 'bg-blue-100 border border-blue-400 text-blue-600'
                : 'bg-red-100 border border-red-400 text-red-600'
            }`}
          >
            {alert ? alert.message : ''}
          </div>
        </form>
      </div>
      <div tw="bg-white rounded border border-gray-400 p-8 md:p-12 mb-6">
        <div tw="font-semibold border-b border-gray-200 pb-2 mb-6">Delete account</div>
        <div tw="text-center">
          <div tw="block mb-2">All your data will be deleted from our servers forever.</div>
          <div tw="block text-red-700 font-semibold mb-6">This action cannot be undone.</div>
          <button
            tw="w-full md:w-auto bg-red-700 text-white px-6 py-3 rounded cursor-pointer"
            onClick={handleDeleteUser}
          >
            Delete
          </button>
        </div>
      </div>
    </SettingsLayout>
  );
};
