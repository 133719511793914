import React, { FC, useCallback } from 'react';
import 'twin.macro';

import { ResponsiveContainer, ComposedChart as ComposedRechart, Line, Bar, XAxis, Legend } from 'recharts';

const stylePaddingTop20 = { paddingTop: '20px' };

type ComposedChart = { selectedCountry: string; data: object[] | undefined };
export const ComposedChart: FC<ComposedChart> = ({ selectedCountry, data }) => (
  <ResponsiveContainer tw="text-sm">
    <ComposedRechart data={data}>
      <Legend
        formatter={useCallback(
          (value) => (
            <span className="text-xs ml-1 mr-2">{value}</span>
          ),
          [],
        )}
        wrapperStyle={stylePaddingTop20}
      />
      <XAxis dataKey="year" tickMargin={8} axisLine={false} />
      <Line type="natural" dataKey="inside" stroke="#a299ea" strokeWidth={1} dot={false} legendType="none" />
      <Bar
        dataKey="inside"
        fill="#a299ea"
        fillOpacity={0.1}
        legendType="plainline"
        maxBarSize={30}
        name={`Days inside ${selectedCountry}`}
        stackId={1}
        stroke="#a299ea"
        strokeWidth={1}
      />
      <Bar
        dataKey="outside"
        fill="#f98c8b"
        fillOpacity={0.1}
        legendType="plainline"
        maxBarSize={30}
        name={`Days outside ${selectedCountry}`}
        stackId={1}
        stroke="#f98c8b"
        strokeWidth={1}
      />
    </ComposedRechart>
  </ResponsiveContainer>
);
