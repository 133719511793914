export type Country = {
  name: string;
  code: string;
  capital: string;
  region: string;
  currency: {
    code: string;
    name: string;
    symbol: string;
  };
  language: {
    code: string;
    iso639_2?: string | undefined;
    name: string;
    nativeName?: string | undefined;
  };
  flag: string;
};

export class CountryService {
  constructor(private store: firebase.firestore.Firestore) {}

  public fetchCountries = async (query: string) => {
    const { docs } = await this.store
      .collection('countries')
      .where('searchableKeywords', 'array-contains', query.trim().toLowerCase())
      .get();

    return docs.map((doc) => doc.data() as Country);
  };

  public fetchCountryByCode = async (query: string) => {
    const { docs } = await this.store.collection('countries').where('code', '==', query.toUpperCase()).get();
    return docs[0].data() as Country;
  };
}
