import React, { FC, useState, useCallback } from 'react';
import * as moment from 'moment';

import 'react-dates/initialize';

import 'react-dates/lib/css/_datepicker.css';
import 'vendors/react-dates.css';

import { DateRangePicker as ReactDateRangePicker } from 'react-dates';

import { useMediaQuery } from 'hooks/use-media-query';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-thick-dot-right.svg';

type Moment = moment.Moment | null | undefined;
type FocusedInput = 'startDate' | 'endDate' | null;
type DateRangePicker = { initialStartDate?: Moment; initialEndDate?: Moment; onChange: Function };

const DATE_FORMAT = 'DD MMM YYYY';

export const DateRangePicker: FC<DateRangePicker> = ({ initialStartDate, initialEndDate, onChange }) => {
  const isSmallScreen = useMediaQuery('(max-width: 640px)');

  const [{ startDate, endDate }, setRange] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });

  const onDatesChange = useCallback(
    ({ startDate, endDate }: any) => {
      setRange({ startDate, endDate });
      onChange({ startDate, endDate });
    },
    [setRange, onChange],
  );

  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const onFocusChange = useCallback(
    (focusedInput: FocusedInput) => {
      setFocusedInput(focusedInput);
    },
    [setFocusedInput],
  );

  return (
    <ReactDateRangePicker
      customArrowIcon={<ArrowRightIcon />}
      displayFormat={DATE_FORMAT}
      endDate={endDate || null}
      endDateId="startDate"
      endDatePlaceholderText="End date"
      focusedInput={focusedInput}
      isOutsideRange={useCallback(() => false, [])}
      numberOfMonths={isSmallScreen ? 1 : 2}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      startDate={startDate || null}
      startDateId="endDate"
      startDatePlaceholderText="Start date"
      withPortal={true}
    />
  );
};
