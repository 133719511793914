import { useState, useEffect, FormEvent } from 'react';
import { Country } from 'services/country.service';
import { useDatastore, useUser } from 'auth.provider';
import { StatsService } from 'services/stats.service';
import { Trip as TripType, TripService } from 'services/trip.service';

export type Trip = TripType;

type StatsForm = { location?: Country };
export type CountryStats = { chartData: object[]; countryName: string };

export const useStats = () => {
  const store = useDatastore();
  const user = useUser();

  const [statsForm, setStatsForm] = useState<StatsForm>({
    location: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [hasTrips] = useState(!!user.totalTrips);
  const [hasStats] = useState(!!user.countriesVisited);
  const [countryStats, setCountryStats] = useState<CountryStats>();
  const [trips, setTrips] = useState<Trip[]>([]);

  useEffect(() => {
    const service = new TripService(store);
    const fetchTrips = async () => {
      setIsLoading(true);
      const { trips: t } = await service.fetchAll(user.id, { limit: 10 });
      setTrips(t);
      setIsLoading(false);
    };
    fetchTrips();
  }, [store, user.id, setTrips, setIsLoading]);

  const onLocationChange = (location?: Country) => setStatsForm({ ...statsForm, location });

  const onFormSubmit = async () => {
    if (!statsForm.location) return;
    const statsService = new StatsService(store);
    const country = `${statsForm.location?.code}_${statsForm.location?.region}`;
    const stats = await statsService.fetchByCountry(user.id, country);
    const chartData = [];
    if (stats) {
      for (const year in stats.inDaysByYear) {
        chartData.push({
          year: parseInt(year),
          inside: stats.inDaysByYear[year],
          outside: stats.outDaysByYear[year],
        });
      }
    }
    setCountryStats({ chartData, countryName: statsForm.location?.name });
  };

  const generateStats = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const statsService = new StatsService(store);
    await statsService.generate(user.id);
    window.location.reload(); // TODO: Refetch user instead
    setIsLoading(false);
  };

  return {
    countryStats,
    generateStats,
    hasStats,
    hasTrips,
    isLoading,
    onFormSubmit,
    onLocationChange,
    statsForm,
    trips,
  };
};
