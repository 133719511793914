import React, { FormEvent, useState, useCallback } from 'react';
import 'twin.macro';

import { SettingsLayout } from './settings.layout';
import { ReactComponent as ProfileIcon } from 'assets/icons/single-neutral-actions-edit-1.svg';
import { LocationAutocomplete } from 'pages/shared-components/location-autocomplete';
import { useUser, useAuth } from 'auth.provider';
import { User } from 'services/user.model';

export const ProfileSettingsPage = () => {
  const user = useUser();

  const { updateProfile } = useAuth();
  const [alert, setAlert] = useState({ isSuccess: false, message: '' });

  const [country, setCountry] = useState<User['country']>(user.country);
  const [profile, setProfile] = useState<User>(user);
  const validateForm = () => !!(profile.name.length && country);

  let locationInput;
  if (profile.country.name.length) {
    locationInput = (
      <LocationAutocomplete
        uid="country"
        text="Home country"
        initialValue={profile.country.name}
        onSelect={setCountry}
      />
    );
  } else {
    locationInput = <input type="text" tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full" />;
  }

  const handleProfileUpdate = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setAlert({ isSuccess: false, message: '' });
      updateProfile(user.id, { name: profile.name, country })
        .then(() => setAlert({ isSuccess: true, message: 'Profile information updated :)' }))
        .catch((e) => setAlert({ isSuccess: false, message: e.message }));
    },
    [country, updateProfile, profile.name, user],
  );

  return (
    <SettingsLayout
      color="purple"
      icon={<ProfileIcon />}
      text="Update personal information like name and home country."
      title="Profile information"
    >
      <div tw="bg-white rounded border border-gray-400 p-8 md:p-12">
        <div tw="font-semibold border-b border-gray-200 pb-2 mb-6">Update profile</div>
        <form tw="mx-auto max-w-full lg:max-w-md" onSubmit={handleProfileUpdate}>
          <fieldset tw="mb-6">
            <label tw="block mb-1" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Email"
              value={profile.email}
              disabled
            />
          </fieldset>
          <fieldset tw="mb-6">
            <label tw="block mb-1" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              type="text"
              tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Name"
              value={profile.name}
              onChange={useCallback((e) => setProfile({ ...profile, name: e.target.value }), [profile, setProfile])}
            />
          </fieldset>
          <fieldset tw="mb-6">{locationInput}</fieldset>
          <fieldset tw="text-right">
            <input
              tw="w-full md:w-auto bg-primary text-white px-6 py-3 rounded cursor-pointer"
              type="submit"
              disabled={!validateForm()}
              value="Update"
            />
            <div
              tw="text-center text-sm px-4 py-3 rounded mt-4"
              className={`${alert.message.length > 0 ? '' : 'hidden'} ${
                alert.isSuccess
                  ? 'bg-blue-100 border border-blue-400 text-blue-600'
                  : 'bg-red-100 border border-red-400 text-red-600'
              }`}
            >
              {alert ? alert.message : ''}
            </div>
          </fieldset>
        </form>
      </div>
    </SettingsLayout>
  );
};
