import React, { FC, useCallback } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUser } from 'auth.provider';

export const AuthenticatedRoute: FC<RouteProps> = ({ component: RouteComponent, ...rest }) => {
  const user = useUser();

  const render = useCallback(
    (routeProps) => {
      if (!RouteComponent) return null;

      return user.isLoggedIn ? (
        user.hasProfile ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to="/profile" />
        )
      ) : (
        <Redirect to="/login" />
      );
    },
    [user.isLoggedIn, user.hasProfile, RouteComponent],
  );

  return <Route {...rest} exact={true} render={render} />;
};
