import React, { FC, useState, useCallback } from 'react';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import 'vendors/react-tag-autocomplete.css';

export const TripTags: FC<{ tags?: string[]; onChange: (tags: string[]) => void }> = ({
  tags: _tags = [],
  onChange,
}) => {
  const [tags, setTags] = useState<Tag[]>(_tags.map((name, id) => ({ id, name })));

  const suggestions = ['Fun', 'Work', 'Study', 'Party', 'Holiday', 'Leisure', 'Business'].map((name, idx) => ({
    id: idx++,
    name,
  }));

  const handleChange = useCallback(
    (tags: Tag[]) => {
      setTags(tags);
      onChange(tags.map((t) => t.name));
    },
    [onChange],
  );

  const handleDelete = useCallback(
    (idx: number) => {
      const newTags = [...tags];
      newTags.splice(idx, 1);
      handleChange(newTags);
    },
    [tags, handleChange],
  );

  const handleAddition = useCallback((tag: Tag) => handleChange([...tags, tag]), [tags, handleChange]);

  return (
    <ReactTags
      allowNew={true}
      autofocus={false}
      handleAddition={handleAddition}
      handleDelete={handleDelete}
      placeholder="Type and hit enter"
      suggestions={suggestions}
      tags={tags}
    />
  );
};
