import React, { FC, useMemo, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { AppLayout } from 'pages/app/app-layout';
import StatsArt from 'assets/illustrations/stats.svg';

import { onLocationSelect, LocationAutocomplete } from 'pages/shared-components/location-autocomplete';
import { ColorCard } from 'pages/shared-components/color-card';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as EarthPlaneIcon } from 'assets/icons/plane-trip-international.svg';
import { ReactComponent as EarthWarningIcon } from 'assets/icons/earth-warning.svg';
import { ReactComponent as PlantSadIcon } from 'assets/icons/plant-sad.svg';

import { useMediaQuery } from 'hooks/use-media-query';

import { useStats, Trip, CountryStats } from './use-stats';
import { useProgressStats, ProgressStatType } from './use-progress-stats';
import { ComposedChart } from './composed-chart';

type onClick = (event: MouseEvent) => void;

export const StatsPage = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const {
    countryStats,
    generateStats,
    hasStats,
    hasTrips,
    isLoading,
    onFormSubmit,
    onLocationChange,
    statsForm,
    trips,
  } = useStats();
  const progressStats = useProgressStats();

  const stats = [];
  for (const key in progressStats) {
    stats.push(<ProgressStat stat={progressStats[key]} key={key} />);
  }

  return (
    <AppLayout title="Stats" isLoading={isLoading}>
      {!isLoading && hasStats && <div tw="flex flex-wrap -mx-4">{stats}</div>}
      {!isLoading && hasStats && (
        <CountryStatsCard
          countryStats={countryStats}
          onLocationSelect={onLocationChange}
          onFormSubmit={onFormSubmit}
          isMobile={isMobile}
        />
      )}
      {!isLoading && hasStats && <TripsStatsTable selectedCountry={statsForm.location?.name} trips={trips} />}

      {!isLoading && !hasStats && hasTrips && <GenerateStats onClick={generateStats} />}
      {!isLoading && !hasStats && !hasTrips && <AddFirstTrip />}
    </AppLayout>
  );
};

type Stats = {
  countryStats: CountryStats | undefined;
  onFormSubmit: onClick;
  onLocationSelect: onLocationSelect;
  isMobile: boolean;
};
const CountryStatsCard: FC<Stats> = ({ countryStats, onFormSubmit, onLocationSelect, isMobile }) => (
  <>
    <div tw="w-full bg-white border border-gray-400 rounded p-6 md:p-12 mb-12">
      <CountryStatsForm onFormSubmit={onFormSubmit} onLocationSelect={onLocationSelect} />
      {countryStats &&
        (countryStats?.chartData.length ? (
          <CountryStatsTable stats={countryStats} isMobile={isMobile} />
        ) : (
          <div tw="text-center mx-auto">
            <PlantSadIcon tw="text-coral w-12 mx-auto mb-4" />
            <div tw="font-display text-xl mb-6">No trips to {countryStats.countryName} found!</div>
            <Link to="/trips/add" tw="inline-block text-white bg-primary rounded px-6 py-3">
              Add trip
            </Link>
          </div>
        ))}
    </div>
  </>
);

const CountryStatsTable: FC<{ stats: CountryStats; isMobile: boolean }> = ({ stats, isMobile }) => (
  <>
    <div tw="flex flex-col-reverse lg:flex-row flex-wrap mt-8">
      <div tw="w-full lg:w-1/2 flex items-center">
        <CountryYearlyStatsTable data={stats.chartData} />
      </div>
      <div tw="w-full lg:w-1/2 flex items-center pt-3 pb-8 lg:pt-6 lg:pb-0 lg:pl-12">
        <div tw="w-full max-h-full" style={useMemo(() => ({ height: isMobile ? '256px' : '400px' }), [isMobile])}>
          <ComposedChart selectedCountry={stats.countryName} data={stats.chartData} />
        </div>
      </div>
    </div>
  </>
);

const CountryCountStatLabel: FC<{ text: string; meta: string; color: string }> = ({ text, meta, color }) => (
  <div tw="flex justify-between text-xs py-2 border-b border-dashed border-opacity-50" className={`border-${color}`}>
    <div tw="uppercase tracking-wider">{text}</div>
    <div tw="font-semibold rounded px-2" className={`bg-${color} bg-opacity-10`}>
      {meta}
    </div>
  </div>
);

const CountryCountPercentage: FC<{ percentage: number; color: string }> = ({ percentage, color }) => (
  <svg viewBox="0 0 36 36" tw="absolute top-0 left-0 w-full" className={`text-${color}`}>
    <path
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeDasharray={percentage ? `${percentage}, 100` : '2, 2'}
    />
  </svg>
);

const ProgressStat: FC<{ stat: ProgressStatType }> = ({ stat: { label, text, labelMeta, percentage, color } }) => (
  <div tw="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
    <div tw="max-w-xl mx-auto">
      <ColorCard color={color}>
        <div tw="flex px-6 py-4">
          <div tw="relative bg-opacity-10 rounded-full p-6" className={`bg-${color}`}>
            {percentage ? <EarthPlaneIcon tw="w-8 h-8" /> : <EarthWarningIcon tw="w-8 h-8" />}
            <CountryCountPercentage percentage={percentage} color={color} />
          </div>
          <div tw="w-full ml-6">
            <CountryCountStatLabel text={label} meta={labelMeta} color={color} />
            <div tw="font-display text-3xl">{text}</div>
          </div>
        </div>
      </ColorCard>
    </div>
  </div>
);

const styleMaxHeightWidth = { height: 'max-content', width: 'max-content' };

type CountryStatsForm = { onFormSubmit: onClick; onLocationSelect: onLocationSelect };
const CountryStatsForm: FC<CountryStatsForm> = ({ onLocationSelect, onFormSubmit }) => (
  <>
    <div tw="font-display text-xl text-center">Country stats</div>
    <hr tw="mt-4 mb-8" />
    <div tw="flex justify-center md:items-end flex-col md:flex-row mb-8">
      <div tw="w-full md:max-w-sm mb-6 mr-0 md:mb-0 md:mr-6">
        <LocationAutocomplete uid="location" text="Select country" onSelect={onLocationSelect} />
      </div>

      <div tw="flex justify-end">
        <button
          type="button"
          tw="flex items-center bg-primary text-white px-6 py-3 border border-primary rounded"
          style={styleMaxHeightWidth}
          onClick={onFormSubmit}
        >
          <SearchIcon tw="h-6" />
        </button>
      </div>
    </div>
  </>
);

const TripsStatsTable: FC<{ trips: Trip[]; selectedCountry: string | undefined }> = ({ trips, selectedCountry }) => (
  <div tw="w-full bg-white border border-gray-400 rounded p-6 md:p-12 mb-12">
    <div tw="font-display text-xl text-center">Trip stats</div>
    <div tw="text-sm text-gray-600 text-center mt-1">Last 10</div>
    <hr tw="mt-4 mb-8" />
    <table tw="table-auto w-full">
      <thead>
        <tr tw="font-bold">
          <td tw="border px-4 py-2 lg:px-6 lg:py-3">Country</td>
          <td tw="border px-4 py-2 lg:px-6 lg:py-3">Days</td>
          <td tw="border px-4 py-2 lg:px-6 lg:py-3 hidden lg:table-cell">Start date</td>
          <td tw="border px-4 py-2 lg:px-6 lg:py-3 hidden lg:table-cell">End date</td>
        </tr>
      </thead>
      <tbody>
        {trips.map(({ startDateFormatted, endDateFormatted, days, country }, idx) => (
          <tr tw="odd:bg-gray-100" key={idx}>
            <td tw="border px-4 py-2 lg:px-6 lg:py-3">
              <span className={`${country.name === selectedCountry ? 'border-b-2 border-purple font-bold' : ''}`}>
                {country.name}
              </span>
            </td>
            <td tw="border px-4 py-2 lg:px-6 lg:py-3">{days}</td>
            <td tw="border px-4 py-2 lg:px-6 lg:py-3 hidden lg:table-cell">{startDateFormatted}</td>
            <td tw="border px-4 py-2 lg:px-6 lg:py-3 hidden lg:table-cell">{endDateFormatted}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const CountryYearlyStatsTable: FC<{ data: any[] }> = ({ data }) => (
  <table tw="table-auto w-full">
    <thead>
      <tr tw="font-bold">
        <td tw="border px-6 py-3">Year</td>
        <td tw="border px-6 py-3">Days inside India</td>
        <td tw="border px-6 py-3">Days outside India</td>
      </tr>
    </thead>
    <tbody>
      {data.map(({ year, inside, outside }, idx) => (
        <tr tw="odd:bg-gray-100" key={idx}>
          <td tw="border px-6 py-3">{year}</td>
          <td tw="border px-6 py-3">{inside}</td>
          <td tw="border px-6 py-3">{outside}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

// TODO: Improve design
const GenerateStats: FC<{ onClick: onClick }> = ({ onClick }) => (
  <div tw="max-w-xl mx-auto bg-white border border-gray-400 rounded mb-10">
    <div tw="flex flex-col items-center justify-center text-center pt-8 md:pt-12 px-4">
      <div tw="font-display text-xl mb-3">Generate stats</div>
      <div tw="mb-6">Let us analyze your data &amp; help you track your travel :)</div>
      <div onClick={onClick} tw="inline-block text-white bg-primary rounded px-6 py-3 cursor-pointer">
        Generate
      </div>
    </div>
    <img src={StatsArt} alt="" tw="w-full pointer-events-none" />
  </div>
);

const AddFirstTrip = () => (
  <div tw="max-w-xl mx-auto bg-white border border-gray-400 rounded">
    <div tw="flex flex-col items-center justify-center text-center pt-8 md:pt-12 px-4">
      <div tw="font-display text-xl mb-3">No trips found!</div>
      <div tw="mb-6">We can provide helpful stats once you add your trips :)</div>
      <Link to="/trips/add" tw="inline-block text-white bg-primary rounded px-6 py-3">
        Add trip
      </Link>
    </div>
    <img src={StatsArt} alt="" tw="w-full pointer-events-none" />
  </div>
);
