import React, { FC, useEffect, useRef } from 'react';
import 'twin.macro';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodataWorld from '@amcharts/amcharts4-geodata/worldLow';

import { useDatastore } from 'auth.provider';
import { Country, CountryService } from 'services/country.service';

import { colors } from 'pages/shared-components/colors';

export const WorldMap: FC<{ countryCode?: string; onSelect: Function }> = ({ countryCode = '', onSelect }) => {
  const store = useDatastore();

  const mapRef = useRef<am4maps.MapChart>();
  const seriesRef = useRef<am4maps.MapPolygonSeries>();
  const activePolygonRef = useRef<am4maps.MapPolygon>();

  useEffect(() => {
    const service = new CountryService(store);

    const map = am4core.create(document.getElementById('amchart') as HTMLElement, am4maps.MapChart);
    map.projection = new am4maps.projections.Miller();
    map.geodata = am4geodataWorld;
    map.zoomDuration = 300;

    const series = new am4maps.MapPolygonSeries();
    series.exclude = ['AQ'];
    series.useGeodata = true;
    map.series.push(series);

    mapRef.current = map;
    seriesRef.current = series;

    const template = series.mapPolygons.template;
    template.tooltipText = '{name}';
    template.fill = am4core.color(colors.white);
    template.stroke = am4core.color(colors.gray400);
    template.strokeWidth = 1;
    template.zIndex = 1;

    const activeState = template.states.create('active');
    activeState.properties.fill = am4core.color(colors.lavender);
    activeState.properties.stroke = am4core.color(colors.primary);
    activeState.properties.zIndex = 2;

    let activePolygon: am4maps.MapPolygon;
    let activeCountry: Country;

    template.events.on('hit', (ev) => {
      if (activePolygon) activePolygon.isActive = false;
      activePolygon = ev.target;
      activePolygonRef.current = activePolygon;
      activePolygon.isActive = true;
      map.zoomToMapObject(activePolygon);

      const data = activePolygon.dataItem.dataContext as Record<string, string>;
      service
        .fetchCountryByCode(data.id)
        .then((val) => {
          if (val.code !== data.id) return;
          activeCountry = val;
          onSelect(activeCountry);
        })
        .catch(console.error);
    });

    return () => {
      map.dispose();
    };
  }, [store, onSelect]);

  useEffect(() => {
    const map = mapRef.current;
    const series = seriesRef.current;
    let activePolygon = activePolygonRef.current;

    if (!map || !series || !countryCode) return;

    if (activePolygon) activePolygon.isActive = false;
    activePolygon = series.getPolygonById(countryCode);
    activePolygon.isActive = true;
    activePolygonRef.current = activePolygon;
    map.zoomToMapObject(activePolygon);
  }, [countryCode]);

  return <div tw="w-full rounded-lg overflow-hidden pl-1" id="amchart"></div>;
};
