import React, { FormEvent, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'twin.macro';

import { useUser } from 'auth.provider';
import useFormFields from '@usereact/use-form-fields';

export const QuickSignup = () => {
  const history = useHistory();
  const user = useUser();
  const { values, fields } = useFormFields({ email: '' });

  const handleQuickSignup = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      history.push(`/signup?email=${values.email}`);
    },
    [history, values.email],
  );

  return (
    <div tw="flex-auto p-6 lg:p-10">
      <h4 tw="font-display text-lg text-center mb-6">Start tracking today!</h4>
      {user.isLoggedIn ? (
        <Link
          to={user.hasProfile ? '/trips' : '/profile'}
          tw="w-full block bg-primary text-white text-center px-6 py-3 rounded"
        >
          {user.hasProfile ? 'Manage trips' : 'Complete signup'}
        </Link>
      ) : (
        <form onSubmit={handleQuickSignup}>
          <fieldset tw="mb-6">
            <label tw="block mb-1" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
              placeholder="Email"
              {...fields.email}
            />
          </fieldset>
          <input
            tw="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer"
            type="submit"
            value="Signup for free"
          />
        </form>
      )}
    </div>
  );
};
