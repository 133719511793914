import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {
  createUser,
  CurrentUser,
  fromFirebase,
  User,
  UserConverter,
  UserProfile,
  UserFromFirebase,
} from './user.model';

export type LoginCreds = { email: string; password: string };
export type UpdatePassword = { email: string; currentPassword: string; newPassword: string };

export class Authenticator {
  private unsubscribeWatchUser: () => void = () => {
    return;
  };

  constructor(private auth: firebase.auth.Auth, private userStore: firebase.firestore.CollectionReference<User>) {}

  static configure(firebase: firebase.app.App) {
    const userStore = firebase.firestore().collection('users').withConverter(UserConverter);
    return new Authenticator(firebase.auth(), userStore);
  }

  public signup = async ({ email, password }: LoginCreds) => {
    const { user } = await this.auth.createUserWithEmailAndPassword(email, password);

    // these errors will never occur
    if (!user) throw new Error('User not recorded');
    if (!user.email) throw new Error("User's email not recorded");

    return fromFirebase(user);
  };

  public createProfile = async (firebaseUser: UserFromFirebase, profile: UserProfile) => {
    const user = createUser(firebaseUser, profile);
    await this.userStore.doc(user.id).set(user);
    return user;
  };

  public updateProfile = async (userId: string, profile: UserProfile) => {
    return await this.userStore.doc(userId).set(profile as User, { merge: true });
  };

  public login = async ({ email, password }: LoginCreds) => {
    const { user } = await this.auth.signInWithEmailAndPassword(email, password);
    if (!user) throw new Error('User not found');
    return fromFirebase(user);
  };

  public signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const { user } = await this.auth.signInWithPopup(provider);

    if (!user) throw new Error('Sign-in with Google failed!');
    return fromFirebase(user);
  };

  public updatePassword = async ({ email, currentPassword, newPassword }: UpdatePassword) => {
    const currentUser = this.auth.currentUser;
    if (!currentUser) throw new Error('User not found');

    const credentials = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
    await currentUser.reauthenticateWithCredential(credentials);
    return await currentUser.updatePassword(newPassword);
  };

  public deleteUser = async ({ email, password }: LoginCreds) => {
    const currentUser = this.auth.currentUser;
    if (!currentUser) throw new Error('User not found');

    const credentials = firebase.auth.EmailAuthProvider.credential(email, password);
    await currentUser.reauthenticateWithCredential(credentials);
    return await currentUser.delete();
  };

  public sendPasswordResetEmail = async (email: string) => await this.auth.sendPasswordResetEmail(email);

  public logout = () => {
    this.auth.signOut();
    this.unsubscribeWatchUser();
  };

  public observeCurrentUser = (callback: (user: CurrentUser) => void) => {
    return this.auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          this.unsubscribeWatchUser = await this.watchUser(user, callback);
        } else {
          callback({ isLoggedIn: false });
          this.unsubscribeWatchUser();
        }
      },
      (error) => callback({ authError: error }),
    );
  };

  private watchUser = async (firebaseUser: firebase.User, callback: (user: CurrentUser) => void) => {
    return this.userStore.doc(firebaseUser.uid).onSnapshot((doc) => {
      const user = doc.data();
      if (user) callback(user);
      else callback(fromFirebase(firebaseUser));
    });
  };
}
