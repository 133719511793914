import React, { FC, FormEvent, ReactNode, useCallback } from 'react';
import 'twin.macro';

import { useToasts } from 'react-toast-notifications';

import { ColorCard } from 'pages/shared-components/color-card';
import { pickColor } from 'pages/shared-components/color-picker';

import { ReactComponent as AnniversaryIcon } from 'assets/icons/wedding-cake.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin-1.svg';
import { ReactComponent as BirthdayIcon } from 'assets/icons/cake-birthday.svg';
import { ReactComponent as CelebrateIcon } from 'assets/icons/party-confetti.svg';
import { DateInput } from './date-input';

import { Notify } from 'services/notify';

import { AppLayout } from '../app-layout';

import { Occasion, useOccasions } from './use-occasions';
import { useAddOccasion } from './use-add-occasion';

const styleTop3 = { top: '3rem' };
const DATE_FORMAT = 'DD MMM YYYY';

export const OccasionsPage = () => {
  const {
    date,
    fields,
    isAnniversaryType,
    isBirthdayType,
    isOtherType,
    isValid,
    deleteById,
    resetForm,
    save,
    setDate,
    setType,
  } = useAddOccasion();
  const { occasions, isLoading, setIsLoading, fetchOccasions, getGroupTitle } = useOccasions();
  const { addToast } = useToasts();

  const getOccasionIcon = (type: string) => {
    switch (type) {
      case 'birthday':
        return <BirthdayIcon />;
      case 'anniversary':
        return <AnniversaryIcon />;
      default:
        return <CelebrateIcon />;
    }
  };

  // TODO: Use react ref
  let resetDateInput: Function = () => {
    return;
  };
  const resetDateInputRef = (fn: Function) => {
    resetDateInput = fn;
  };

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      setIsLoading(true);
      save()
        .then(() => {
          resetForm();
          resetDateInput();
          fetchOccasions();
          setIsLoading(false);
          addToast('Occasion added successfully', { appearance: 'success', autoDismiss: true });
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [fetchOccasions, resetForm, save, setIsLoading, addToast],
  );

  const handleDelete = useCallback(
    (occasionId) => {
      Notify.confirm({
        title: 'Delete occasion',
        text: 'Are you sure?',
        isDestructive: true,
      }).then(({ isDismissed }) => {
        if (isDismissed) return;
        setIsLoading(true);
        deleteById(occasionId)
          .then(() => {
            fetchOccasions();
            setIsLoading(false);
            addToast('Occasion removed successfully', { appearance: 'success', autoDismiss: true });
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
    },
    [fetchOccasions, deleteById, setIsLoading, addToast],
  );

  let idx = 0;
  const occasionsJsx = [];
  for (const group in occasions) {
    idx++;
    occasionsJsx.push(
      <OccasionGroup
        color={pickColor(idx)}
        getOccasionIcon={getOccasionIcon}
        key={idx}
        list={occasions[group]}
        title={getGroupTitle(group)}
        onDelete={handleDelete}
      />,
    );
  }

  return (
    <AppLayout title="Occasions" isLoading={isLoading}>
      <div tw="flex justify-center flex-wrap -mx-4">
        <div tw="w-full md:w-1/2 xl:w-2/5 relative px-4 mb-8">
          <form tw="sticky" style={styleTop3} onSubmit={handleSubmit}>
            <div tw="flex flex-row justify-center xl:justify-start">
              <div tw="bg-white rounded border border-gray-400 w-full p-8">
                <div>
                  <div tw="font-display text-xl">Add Occasion</div>
                  <div tw="text-gray-600 mt-1">Birthday, Anniversary, Graduation, etc.</div>
                </div>

                <hr tw="my-8" />

                <div tw="relative mb-6">
                  <label tw="block mb-1">Title</label>
                  <input
                    type="text"
                    tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
                    placeholder="Enter title - e.g. Jane's Birthday"
                    {...fields.title}
                  />
                </div>

                <div tw="relative mb-6">
                  <label tw="block mb-1">Type</label>
                  <div tw="flex flex-wrap my-2">
                    <OccasionType
                      type="birthday"
                      icon={<BirthdayIcon />}
                      isSelected={isBirthdayType}
                      onSelect={setType}
                    />
                    <OccasionType
                      type="anniversary"
                      icon={<AnniversaryIcon />}
                      isSelected={isAnniversaryType}
                      onSelect={setType}
                    />
                    <OccasionType type="" icon={<CelebrateIcon />} isSelected={isOtherType} onSelect={setType} />
                  </div>
                  {isOtherType && (
                    <input
                      type="text"
                      tw="p-3 capitalize placeholder-gray-400 border border-gray-400 rounded w-full"
                      placeholder="Enter custom type"
                      {...fields.type}
                    />
                  )}
                </div>

                <div tw="relative mb-6">
                  <label tw="flex items-center justify-between mb-1">
                    Date {date && <span tw="text-sm text-gray-600">{date.format(DATE_FORMAT)}</span>}
                  </label>
                  <DateInput onSet={setDate} resetRef={resetDateInputRef} />
                </div>
                <input
                  tw="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:(bg-gray-500 cursor-not-allowed)"
                  disabled={!isValid() || isLoading}
                  type="submit"
                  value={isLoading ? 'Please wait...' : 'Add'}
                />
              </div>
            </div>
          </form>
        </div>
        <div tw="w-full md:w-1/2 xl:w-3/5 px-4 mb-8">{!!occasionsJsx.length && occasionsJsx}</div>
      </div>
    </AppLayout>
  );
};

type OccasionType = { type: string; icon: ReactNode; isSelected: boolean; onSelect: Function };
const OccasionType: FC<OccasionType> = ({ type, icon, isSelected, onSelect }) => (
  <div tw="mb-3 mr-3 last:mr-0 flex-1">
    <div
      onClick={useCallback(() => onSelect(type), [type, onSelect])}
      tw="w-full flex justify-center items-center border hover:border-solid rounded text-sm p-3 cursor-pointer transition-all"
      className={isSelected ? 'font-semibold text-purple border-solid border-purple' : 'border-dashed border-gray-400'}
    >
      <div tw="w-5 mr-2">{icon}</div>
      <div tw="capitalize">{type || 'custom'}</div>
    </div>
  </div>
);

type OccasionGroup = { title: string; color: string; list: Occasion[]; getOccasionIcon: Function; onDelete: Function };
const OccasionGroup: FC<OccasionGroup> = ({ title, color, list, getOccasionIcon, onDelete }) => (
  <div tw="mb-8 last:mb-0">
    <div tw="text-xs text-right font-bold tracking-widest uppercase p-2" className={`border-b-2 border-${color}`}>
      {title}
    </div>
    <ColorCard color={color} colorWidth={1}>
      <div tw="p-6">
        {list.map((item, idx) => (
          <OccasionItem occasion={item} icon={getOccasionIcon(item.type)} color={color} key={idx} onDelete={onDelete} />
        ))}
      </div>
    </ColorCard>
  </div>
);

type OccasionItem = { occasion: Occasion; icon: ReactNode; color: string; onDelete: Function };
const OccasionItem: FC<OccasionItem> = ({ occasion, icon, color, onDelete }) => {
  const handleDelete = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onDelete(occasion.id);
    },
    [occasion.id, onDelete],
  );

  return (
    <div tw="flex items-center border-b border-gray-200 mb-3 pb-3 last:mb-0 last:pb-0 last:border-none">
      <div tw="bg-opacity-10 rounded p-3 mr-4" className={`bg-${color}`}>
        <div tw="w-6">{icon}</div>
      </div>
      <div tw="w-full flex items-center justify-between">
        <div>
          <div>
            {occasion.title} - <span tw="capitalize">{occasion.type}</span>
          </div>
          <div tw="text-sm text-gray-600">{occasion.dateFormatted}</div>
        </div>
        <div>
          <div tw="text-gray-500 hover:text-red-700 cursor-pointer ml-3" onClick={handleDelete}>
            <BinIcon tw="w-5" />
          </div>
        </div>
      </div>
    </div>
  );
};
