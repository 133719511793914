import React from 'react';
import 'twin.macro';

import { SettingsLayout } from './settings.layout';

import { ReactComponent as AccountIcon } from 'assets/icons/credit-card-dollar.svg';

export const AccountSettingsPage = () => {
  return (
    <SettingsLayout
      color="green"
      icon={<AccountIcon />}
      text="Review payment information, past invoices and transactions."
      title="Account summary"
    >
      <div tw="bg-white rounded border border-gray-400 p-8 md:p-12">
        <div tw="text-lg">TODO</div>
      </div>
    </SettingsLayout>
  );
};
