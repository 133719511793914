import { useState, useCallback } from 'react';
import { useDatastore, useUser } from 'auth.provider';
import { Trip as TripType, FirebaseDoc, TripService } from 'services/trip.service';
import { useEffectOnce } from 'hooks/use-effect-once';

export type Trip = TripType;

export const useTrips = (qsTag: string | undefined) => {
  const user = useUser();
  const store = useDatastore();

  const [currentTag, setCurrentTag] = useState(qsTag);

  const [trips, setTrips] = useState<Trip[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasMoreTrips, setHasMoreTrips] = useState(!!user.totalTrips);
  const [lastDoc, setLastDoc] = useState<FirebaseDoc>(0);

  const colors = ['yellow', 'coral', 'skyblue', 'purple', 'green', 'yellow', 'pink'];
  const pickColor = (idx: number) => colors[idx % colors.length];

  const fetchTrips = useCallback(
    ({ tag, isReset = false } = {}) => {
      setCurrentTag(tag);
      setHasMoreTrips(false);
      if (isReset) setHasLoaded(false);

      const service = new TripService(store);
      const tags = tag ? [tag] : undefined;

      setIsLoading(true);
      service.fetchAll(user.id, { startAfter: isReset ? 0 : lastDoc, tags }).then(({ trips: t, lastDoc: ld }) => {
        setLastDoc(ld);
        setTrips(isReset ? t : trips.concat(t));
        setHasMoreTrips(!!user.totalTrips && !!trips.length && user.totalTrips > trips.length);
        setHasLoaded(true);
        setIsLoading(false);
      });
    },
    [lastDoc, store, trips, user.id, user.totalTrips],
  );

  const loadMoreTrips = useCallback(() => {
    if (!isLoading && hasMoreTrips) fetchTrips({ tag: currentTag });
  }, [currentTag, fetchTrips, hasMoreTrips, isLoading]);

  useEffectOnce(() => {
    fetchTrips({ tag: currentTag });
  });

  const watchTag = useCallback(
    (qsTag) => {
      if (qsTag !== currentTag) fetchTrips({ tag: qsTag, isReset: true });
    },
    [currentTag, fetchTrips],
  );

  return { trips, hasLoaded, isLoading, pickColor, loadMoreTrips, watchTag };
};
