export type UserProfile = {
  name: string;
  country: { name: string; code: string; region: string };
  totalTrips?: number;
  tags?: string[];
  countriesVisited?: { [key: string]: { [key: string]: boolean } };
};

export type UserFlags = { isLoggedIn?: boolean; hasProfile?: boolean };
export type UserFromFirebase = { id: string; email: string; creationTime: string };
export type User = UserProfile & UserFromFirebase & UserFlags;

export type CurrentUser =
  | User
  | UserFromFirebase
  | { isLoading: true }
  | { isLoggedIn: false }
  | { authError: firebase.auth.Error };

export const UserConverter: firebase.firestore.FirestoreDataConverter<User> = {
  toFirestore: ({ country, ...user }: User) => ({
    ...user,
    country: { name: country.name, code: country.code, region: country.region },
  }),
  fromFirestore: (snapshot) => {
    const data = snapshot.data();
    const country = data.country || {};

    return {
      id: data.id,
      email: data.email,
      name: data.name,
      totalTrips: data.totalTrips,
      tags: data.tags,
      countriesVisited: data.countriesVisited,
      creationTime: data.creationTime,
      country: { name: country.name, code: country.code, region: country.region },
      isLoggedIn: true,
      hasProfile: true,
    };
  },
};

export const fromFirebase = (firebaseUser: firebase.User): UserFromFirebase & UserFlags => ({
  id: firebaseUser.uid,
  email: firebaseUser.email!,
  creationTime: firebaseUser.metadata.creationTime!,
  isLoggedIn: true,
  hasProfile: false,
});

export const createUser = (user: UserFromFirebase, profile: UserProfile): User => ({
  id: user.id,
  email: user.email,
  creationTime: user.creationTime,
  ...profile,
});
