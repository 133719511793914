import React, { FC, useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react';
import Autosuggest from 'react-autosuggest';
import 'twin.macro';

import 'vendors/react-autosuggest.css';
import { useDatastore } from 'auth.provider';
import { Country, CountryService } from 'services/country.service';

export type onLocationSelect = (country: Country) => void;

type LocationAutocomplete = {
  uid: string;
  text: string;
  initialValue?: string;
  placeholder?: string;
  onSelect: onLocationSelect;
};

const LocationAutosuggest = Autosuggest as new () => Autosuggest<Country>;

export const LocationAutocomplete: FC<LocationAutocomplete> = ({
  uid,
  text,
  initialValue = '',
  placeholder = 'Enter country name',
  onSelect,
}) => {
  const store = useDatastore();
  const service = new CountryService(store);

  const [value, setValue] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([] as Country[]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = useCallback(
    (_: ChangeEvent, { newValue }: { newValue: string }) => {
      setValue(newValue);
    },
    [setValue],
  );

  const selectSuggestion = useCallback(
    (country: Country) => {
      onSelect(country);
      return country.name;
    },
    [onSelect],
  );

  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
  }, [setSuggestions]);

  const fetchSuggestions = useCallback(
    ({ value }: { value: string }) => {
      if (value.length > 1) {
        service.fetchCountries(value).then(setSuggestions).catch(console.error);
      }
    },
    [service],
  );

  const renderSuggestion = useCallback((country: Country) => {
    return (
      <div tw="flex items-center text-sm">
        <img src={country.flag} alt="" tw="w-6 rounded-sm mr-4 pointer-events-none" />
        <span>{country.name}</span>
      </div>
    );
  }, []);

  const inputProps = useMemo(() => ({ onChange, placeholder, value }), [onChange, placeholder, value]);

  return (
    <>
      <label tw="block mb-1" htmlFor={uid}>
        {text}
      </label>
      <LocationAutosuggest
        getSuggestionValue={selectSuggestion}
        inputProps={inputProps}
        multiSection={false}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionsFetchRequested={fetchSuggestions}
        renderSuggestion={renderSuggestion}
        suggestions={suggestions}
      />
    </>
  );
};
