import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import LogoImage from 'assets/images/logo.svg';
import LightLogoImage from 'assets/images/logo-light.svg';

export const Logo: FC<{ useLight?: boolean }> = ({ useLight = false }) => {
  return (
    <Link to="/">
      <img src={useLight ? LightLogoImage : LogoImage} alt="ExpaTrack" tw="h-10 pointer-events-none" />
    </Link>
  );
};
