import React from 'react';
import 'twin.macro';

import { Illustration } from './illustration';
import { MarketingLayout } from './marketing-layout';

export const NotFoundPage = () => {
  return (
    <MarketingLayout title="404 Error" subtitle="We can't find the page you're looking for :(">
      <section tw="mx-auto max-w-xl px-4">
        <Illustration icon="404" />
      </section>
    </MarketingLayout>
  );
};
