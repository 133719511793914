import React, { FormEvent, useState, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import 'twin.macro';

import useFormFields from '@usereact/use-form-fields';
import { useAuth, useUser } from 'auth.provider';
import { AuthLayout } from './login.layout';

export const ResetPage = () => {
  const user = useUser();
  const { sendPasswordResetEmail } = useAuth();
  const { values: credentials, fields } = useFormFields({ email: '' });
  const validateForm = () => credentials.email.length;
  const [alert, setAlert] = useState({ isSuccess: false, message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      setAlert({ isSuccess: false, message: '' });

      sendPasswordResetEmail(credentials.email)
        .then(() => {
          setAlert({
            isSuccess: true,
            message: 'Reset password instructions email sent :)',
          });
          setIsLoading(false);
        })
        .catch((e) => {
          setAlert({ isSuccess: false, message: e.message });
          setIsLoading(false);
        });
    },
    [credentials.email, sendPasswordResetEmail],
  );

  if (user.isLoggedIn) return <Redirect to="/trips" />;

  return (
    <AuthLayout title="Forgot your password?" isLoading={isLoading}>
      <form onSubmit={handleResetPassword}>
        <fieldset tw="mb-6">
          <label tw="block mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
            placeholder="Email"
            {...fields.email}
          />
        </fieldset>
        <fieldset tw="mb-6">
          <input
            tw="w-full bg-primary text-white px-6 py-3 rounded cursor-pointer disabled:(bg-gray-500 cursor-not-allowed)"
            disabled={!validateForm() || isLoading}
            type="submit"
            value={isLoading ? 'Please wait...' : 'Reset password'}
          />
        </fieldset>
        <div
          tw="text-center text-sm px-4 py-3 rounded my-4"
          className={`${alert.message.length > 0 ? '' : 'hidden'} ${
            alert.isSuccess
              ? 'bg-blue-100 border border-blue-400 text-blue-600'
              : 'bg-red-100 border border-red-400 text-red-600'
          }`}
        >
          {alert ? alert.message : ''}
        </div>
      </form>
      <div tw="text-center">
        <Link to="/login" tw="text-primary">
          Back to login
        </Link>
      </div>
    </AuthLayout>
  );
};
