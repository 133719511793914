import { useState, useCallback } from 'react';
import { Country } from 'services/country.service';
import { useDatastore, useUser } from 'auth.provider';
import { Trip as TripType, DateRange, TripService } from 'services/trip.service';

export type Trip = TripType;

export const useAddTrip = () => {
  const store = useDatastore();
  const user = useUser();
  const service = new TripService(store);

  const [trip, setTrip] = useState<Partial<Trip>>({
    tags: [],
    country: undefined,
  });

  const changeDates = ({ startDate, endDate }: DateRange) => {
    setTrip({ ...trip, startDate, endDate });
  };

  const changeTags = (tags: string[]) => {
    setTrip({ ...trip, tags });
  };

  const changeCountry = useCallback((country: Country) => setTrip((t) => ({ ...t, country })), []);

  const isValid = (t = trip): t is Required<Trip> => {
    const datesValid = !!(t.startDate && t.endDate && t.startDate < t.endDate);
    const countryValid = !!t.country;

    return datesValid && countryValid;
  };

  const save = async () => {
    if (!isValid(trip)) return new Error('This is a programming error.');
    await service.add(user.id, trip);
  };

  return {
    data: trip,
    changeDates,
    changeTags,
    changeCountry,
    save,
    isValid,
  };
};
