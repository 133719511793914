import React, { FC } from 'react';
import 'twin.macro';

import SkeletonText, { SkeletonTheme } from 'react-loading-skeleton';

export const Skeleton: FC<{ classNames: string }> = ({ classNames }) => (
  // gray-200 | gray-400
  <SkeletonTheme color="#edf2f7" highlightColor="#cbd5e0">
    <div className={`block ${classNames}`}>
      <SkeletonText height={14} />
    </div>
  </SkeletonTheme>
);
