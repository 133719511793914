// TODO - update `.env-sample` everytime you update this EnvVars type
type EnvVars = object &
  Partial<{
    REACT_APP_API_KEY: string;
    REACT_APP_APP_ID: string;
    REACT_APP_AUTH_DOMAIN: string;
    REACT_APP_PROJECT_ID: string;
  }>;

const throwIfUndefined = <T>(params: { [P in keyof T]?: T[P] }) => {
  const undefinedKeys = Object.entries(params)
    .filter(([, val]) => !val)
    .map(([key]) => key);

  if (undefinedKeys.length) {
    throw new Error(`No values found for keys: [${undefinedKeys.join(', ')}]`);
  }

  return params as Required<T>;
};

export class Environment {
  constructor(private config: EnvVars = process.env) {}

  get firebase() {
    return throwIfUndefined({
      apiKey: this.config.REACT_APP_API_KEY,
      appId: this.config.REACT_APP_APP_ID,
      authDomain: this.config.REACT_APP_AUTH_DOMAIN,
      projectId: this.config.REACT_APP_PROJECT_ID,
    });
  }
}
