import React, { createContext, FC, useContext, useLayoutEffect, useState } from 'react';
import firebase from 'firebase/app';

import { Environment } from 'environment';
import { SplashScreen } from 'pages/shared-components/splash-screen';
import { Authenticator } from 'services/authenticator';
import { CurrentUser, User } from 'services/user.model';

const env = new Environment();
const defaultFirebase = firebase.apps.length ? firebase.app() : firebase.initializeApp(env.firebase);

const authenticator = Authenticator.configure(defaultFirebase);
const datastore = defaultFirebase.firestore();

const DatastoreContext = createContext(datastore);
export const useDatastore = () => useContext(DatastoreContext);

const AuthContext = createContext(authenticator);
export const useAuth = () => useContext(AuthContext);

const UserContext = createContext<CurrentUser>({ isLoading: true });

export const AuthProvider: FC<{ auth?: Authenticator; store?: firebase.firestore.Firestore; user?: CurrentUser }> = ({
  auth = authenticator,
  store = datastore,
  user = { isLoading: true },
  ...props
}) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>(user);

  useLayoutEffect(() => auth.observeCurrentUser(setCurrentUser), [auth]);

  if ('isLoading' in currentUser) return <SplashScreen />;

  return (
    <AuthContext.Provider value={auth}>
      <DatastoreContext.Provider value={store}>
        <UserContext.Provider value={currentUser} {...props} />
      </DatastoreContext.Provider>
    </AuthContext.Provider>
  );
};

export const useUser = () => {
  const user = useContext(UserContext);
  return user as User;
};
