import firebase from 'firebase/app';
import moment, { Moment } from 'moment';
import { Country } from './country.service';

export type DateRange = { startDate: Moment; endDate: Moment };
type DateRangeFormatted = { startDateFormatted: string; endDateFormatted: string };

export type Trip = DateRange & DateRangeFormatted & { id: string; country: Country; days: number; tags: string[] };
export type FirebaseDoc = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | 0;
const DATE_FORMAT = 'DD MMM YYYY';

const TripConverter: firebase.firestore.FirestoreDataConverter<Trip> = {
  toFirestore: (trip: Trip) => ({
    country: trip.country,
    startDate: firebase.firestore.Timestamp.fromDate(trip.startDate.toDate()),
    endDate: firebase.firestore.Timestamp.fromDate(trip.endDate.toDate()),
    tags: trip.tags,
  }),
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    // moment() objects
    const startDate = moment(data.startDate.seconds * 1000);
    const endDate = moment(data.endDate.seconds * 1000);

    return {
      id: snapshot.id,
      country: data.country,

      startDate,
      endDate,
      startDateFormatted: startDate.format(DATE_FORMAT),
      endDateFormatted: endDate.format(DATE_FORMAT),

      days: endDate.diff(startDate, 'days'),
      tags: data.tags || data.labels,
    };
  },
};

export class TripService {
  constructor(private store: firebase.firestore.Firestore) {}

  public add = async (userId: string, trip: Trip) => {
    const newTripRef = this.store
      .collection('users')
      .doc(userId)
      .collection('trips')
      .withConverter(TripConverter)
      .doc();

    const batch = this.store.batch();
    batch.set(newTripRef, trip);

    const userRef = this.store.collection('users').doc(userId);
    const totalTrips = firebase.firestore.FieldValue.increment(1);
    const userTags = firebase.firestore.FieldValue.arrayUnion(...trip.tags);
    batch.set(userRef, { totalTrips, tags: userTags }, { merge: true });

    await batch.commit();
  };

  public fetchAll = async (
    userId: string,
    { tags, limit = 20, startAfter = 0 }: { tags?: string[]; limit?: number; startAfter?: FirebaseDoc } = {},
  ) => {
    let query = this.store
      .collection('users')
      .doc(userId)
      .collection('trips')
      .withConverter(TripConverter)
      .orderBy('startDate', 'desc');

    if (tags) query = query.where('tags', 'array-contains-any', tags);
    if (startAfter) query = query.startAfter(startAfter);
    if (limit) query = query.limit(limit);

    const { docs } = await query.get();

    const lastDoc = docs.length && docs[docs.length - 1];
    const trips = docs.map((doc) => doc.data());

    return { lastDoc, trips };
  };
}
