import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { AppLayout } from '../app-layout';
import { SettingsCard } from './settings-card';
import { ColorCard } from 'pages/shared-components/color-card';

import { ReactComponent as AccountIcon } from 'assets/icons/credit-card-dollar.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/single-neutral-actions-edit-1.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/alarm-bell.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/lock-8.svg';

import SettingsArt from 'assets/illustrations/settings.svg';
import { useUser } from 'auth.provider';
import moment from 'moment';

export const SettingsPage = () => {
  const user = useUser();

  return (
    <AppLayout title="Settings">
      <div tw="flex flex-wrap -mx-4">
        <div tw="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
          <ColorCard color="gray-500">
            <div tw="text-center p-8 mb-4">
              <div tw="text-3xl font-display mb-2">{user.name}</div>
              <div>Joined in {moment(user.creationTime).format('MMM YYYY')}</div>
            </div>
            <img src={SettingsArt} alt="" tw="w-full pointer-events-none" />
          </ColorCard>
        </div>
        <div tw="w-full md:w-1/2 xl:w-2/3 md:px-4 mb-8 flex flex-wrap content-start">
          <Setting
            color="purple"
            icon={<ProfileIcon />}
            linkTo="/settings/profile"
            text="Update personal information like name and home country."
            title="Profile information"
          />
          {false && (
            <>
              <Setting
                color="green"
                icon={<AccountIcon />}
                linkTo="/settings/account"
                text="Review payment information, past invoices and transactions."
                title="Account summary"
              />
              <Setting
                color="yellow"
                icon={<NotificationIcon />}
                linkTo="/settings/notifications"
                text="Choose type of notifications you want to receive."
                title="Notification preferences"
              />
            </>
          )}
          <Setting
            color="coral"
            icon={<SecurityIcon />}
            linkTo="/settings/security"
            text="Change your password or permanently delete your account."
            title="Privacy &amp; security"
          />
        </div>
      </div>
    </AppLayout>
  );
};

type Setting = { color: string; icon: React.ReactNode; linkTo: string; text: string; title: string };

const Setting: FC<Setting> = ({ color, icon, linkTo, text, title }) => (
  <div tw="w-full xl:w-1/2 px-4 mb-8">
    <Link to={linkTo} tw="block">
      <SettingsCard color={color} icon={icon} text={text} title={title} />
    </Link>
  </div>
);
