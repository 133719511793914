import React, { FC } from 'react';
import 'twin.macro';

export const ColorCard: FC<{ color: string; colorWidth?: number }> = ({ color, colorWidth = 4, children }) => {
  return (
    <div tw="block bg-white rounded-b border-l border-r border-b border-gray-400">
      <div className={`border-t-${colorWidth} border-${color}`}>{children}</div>
    </div>
  );
};
