import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { AppLayout } from '../app-layout';
import TravelArt from 'assets/illustrations/travel.svg';
import { Skeleton } from 'pages/shared-components/skeleton';
import { pickColor } from 'pages/shared-components/color-picker';

import { ReactComponent as RightArrowIcon } from 'assets/icons/arrow-thick-dot-right.svg';
import { ReactComponent as HashtagIcon } from 'assets/icons/hash.svg';

import { useQuery } from 'hooks/use-query';

import { Trip, useTrips } from './use-trips';

export const TripsPage = () => {
  const query = useQuery();
  const tag = query.tag as string;

  const { trips, hasLoaded, isLoading, loadMoreTrips, watchTag } = useTrips(tag);
  const hasTrips = trips && !!trips.length;
  watchTag(tag);

  return (
    <AppLayout title={'Trips' + (tag ? ` for #${tag}` : '')} isLoading={isLoading} onScrollEnd={loadMoreTrips}>
      {!hasLoaded && isLoading ? <SkeletonTripCards /> : ''}
      {hasLoaded && hasTrips && (
        <div tw="flex flex-wrap -mx-4">
          {trips.map((trip, idx) => (
            <TripCard trip={trip} color={pickColor(idx)} key={trip.id} />
          ))}
          <AddTripCard />
        </div>
      )}
      {hasLoaded && !isLoading && !hasTrips && <AddFirstTrip tag={tag} />}
    </AppLayout>
  );
};

const TripCard: FC<{ trip: Trip; color: string }> = ({ trip, color }) => (
  <div tw="w-full lg:w-1/2 xl:w-1/3 px-4 mb-8">
    <div tw="h-full relative bg-white border border-brand rounded overflow-hidden">
      <div
        tw="absolute top-0 right-0 w-12 h-12 border-l border-b rounded-bl-full border-brand text-dark"
        className={`bg-${color}`}
      >
        <RightArrowIcon tw="w-5 absolute right-0 top-0 mt-2 mr-2" />
      </div>
      <div tw="flex flex-col p-8">
        <div tw="flex items-center border-b pb-3 mb-3">
          <img src={trip.country.flag} alt="" tw="h-4 rounded-sm pointer-events-none" />
          <div tw="text-lg ml-3">{trip.country.name}</div>
        </div>
        <div tw="text-sm">
          {trip.startDateFormatted} - {trip.endDateFormatted}
        </div>
        {trip.tags && (
          <div tw="flex flex-wrap mt-2">
            {trip.tags.map((tag) => {
              return <TripTag key={tag} tag={tag} />;
            })}
          </div>
        )}
      </div>
    </div>
  </div>
);

const SkeletonTripCards = () => (
  <div tw="flex flex-wrap -mx-4">
    {[...Array(12)].map((_, i) => (
      <SkeletonTripCard key={i} />
    ))}
  </div>
);

const SkeletonTripCard = () => (
  <div tw="w-full lg:w-1/2 xl:w-1/3 px-4 mb-8">
    <Link
      to="/trips/add"
      className="group"
      tw="block h-full relative bg-white border border-dashed border-gray-500 rounded overflow-hidden"
    >
      <div tw="absolute top-0 right-0 w-12 h-12 border-l border-b border-dashed rounded-bl-full border-gray-500 bg-gray-100"></div>
      <div tw="flex flex-col p-8">
        <div tw="border-b pb-3 mb-3">
          <Skeleton classNames="w-2/3" />
        </div>
        <Skeleton classNames="w-full mb-2" />
        <Skeleton classNames="w-2/3" />
      </div>
    </Link>
  </div>
);

const AddTripCard = () => (
  <div tw="w-full lg:w-1/2 xl:w-1/3 px-4 mb-8">
    <Link
      to="/trips/add"
      className="group"
      tw="block h-full relative bg-white border border-dashed border-gray-600 rounded overflow-hidden transition-colors duration-300"
    >
      <div tw="absolute top-0 right-0 w-12 h-12 border-l border-b border-dashed rounded-bl-full border-gray-600 text-gray-600 bg-gray-100 transition-colors duration-300">
        <RightArrowIcon tw="w-5 absolute right-0 top-0 mt-2 mr-2" />
      </div>
      <div tw="flex flex-col p-8">
        <div tw="flex items-center border-b pb-3 mb-3">
          <div tw="text-lg">Add another trip</div>
        </div>
        <div tw="text-sm leading-loose">
          Add all your missing trips! ExpaTrack is most useful when all your travels have been logged.
        </div>
      </div>
    </Link>
  </div>
);

const TripTag: FC<{ tag: string }> = ({ tag }) => {
  return (
    <div tw="flex text-xs leading-none mr-3 mt-4">
      <HashtagIcon tw="w-3 h-3 mr-1" />
      <div>{tag}</div>
    </div>
  );
};

const AddFirstTrip: FC<{ tag: string }> = ({ tag }) => (
  <div tw="max-w-xl mx-auto bg-white border border-gray-400 rounded">
    <div tw="flex flex-col items-center justify-center text-center py-8 md:py-12 px-4">
      <div tw="font-display text-xl mb-3">
        No trips found
        {tag ? (
          <span>
            {' '}
            for <span tw="text-primary">#{tag}</span>
          </span>
        ) : (
          '!'
        )}
      </div>
      <div tw="mb-6">When you add trips, they will appear here :)</div>
      <Link to="/trips/add" tw="inline-block text-white bg-primary rounded px-6 py-3">
        Add trip
      </Link>
    </div>
    <img src={TravelArt} alt="" tw="w-full pointer-events-none" />
  </div>
);
