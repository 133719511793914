import React from 'react';
import 'twin.macro';

import LogoImage from 'assets/images/logo.svg';
import { ReactComponent as LoadingImage } from 'assets/images/loading.svg';

export const SplashScreen = () => (
  <div tw="flex items-center justify-center min-h-screen bg-white">
    <div tw="w-48 pointer-events-none">
      <img src={LogoImage} alt="ExpaTrack" />
      <div tw="w-12 mx-auto mt-2 text-green">
        <LoadingImage tw="block w-full" />
      </div>
    </div>
  </div>
);
