import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { ReactComponent as FacebookIcon } from 'assets/icons/social-media-facebook-1.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/social-instagram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/social-media-twitter.svg';
import { ReactComponent as BuyCoffeeIcon } from 'assets/images/buy-coffee.svg';

export const Footer = () => {
  return (
    <footer tw="relative bg-white px-4 py-10 lg:py-20">
      <div tw="max-w-lg mx-auto">
        <BuyCoffeeButton />
        <div tw="block sm:flex items-center justify-center text-center">
          <FooterLink to="/privacy" text="Privacy" />
          <FooterLink to="/terms" text="Terms of use" />
          <FooterLink to="/about" text="About" />
        </div>
        <hr tw="my-6" />
        <div tw="flex justify-center">
          <SocialLink icon={<FacebookIcon />} link="https://facebook.com/expatrack" />
          <SocialLink icon={<InstagramIcon />} link="https://instagram.com/expatrack" />
          <SocialLink icon={<TwitterIcon />} link="https://twitter.com/expatrack" />
        </div>
        <Copyright />
      </div>
    </footer>
  );
};

const FooterLink: FC<{ to: string; text: string }> = ({ to, text }) => (
  <div tw="m-3">
    <Link to={to}>{text}</Link>
  </div>
);

const SocialLink: FC<{ icon: React.ReactNode; link: string }> = ({ icon, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" tw="text-purple w-8 h-auto m-4">
    {icon}
  </a>
);

const Copyright = () => <div tw="p-4 text-center opacity-50">&copy; {new Date().getFullYear()} ExpaTrack</div>;

const BMC_COLOR = '#fe4b0d';
const styleBuyCoffeeColor = { color: BMC_COLOR, borderColor: BMC_COLOR };

const BuyCoffeeButton = () => (
  <div tw="flex items-center justify-center mb-6">
    <a
      href="https://www.buymeacoffee.com/expatrack"
      target="_blank"
      rel="noopener noreferrer"
      style={styleBuyCoffeeColor}
      tw="flex items-center border-2 font-semibold rounded px-4 py-2"
    >
      <BuyCoffeeIcon tw="w-5 mr-3" />
      Buy us a coffee :)
    </a>
  </div>
);
