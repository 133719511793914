import React, { FC } from 'react';
import 'twin.macro';

import { Illustration } from './illustration';

type IllustrationCard = {
  title: string;
  icon: string;
  color: string;
  isOffset?: boolean;
};

const styleBottomRight = { bottom: '-10px', right: '-10px' };

export const IllustrationCard: FC<IllustrationCard> = ({ title, icon, color, isOffset, children }) => {
  return (
    <div tw="w-full lg:w-1/3 px-6 text-center" className={`${isOffset ? 'pt-12 lg:pt-12' : 'pt-12 lg:pt-0'}`}>
      <div tw="relative flex flex-col">
        <div tw="relative p-8 flex-auto bg-white border-2 border-brand rounded-lg z-10">
          <div tw="w-20 h-20 mx-auto mb-4">
            <Illustration icon={icon} />
          </div>
          <h6 tw="font-display text-2xl">{title}</h6>
          <p tw="break-words mt-2">{children}</p>
        </div>
        <div
          className={`bg-${color}`}
          tw="absolute w-full h-full flex-auto border-2 border-brand rounded-lg"
          style={styleBottomRight}
        ></div>
      </div>
    </div>
  );
};
