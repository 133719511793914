import firebase from 'firebase/app';
import moment, { Moment } from 'moment';

export type Occasion = { id?: string; title: string; type: string; date: Moment | null; dateFormatted?: string };

export class OccasionService {
  constructor(private store: firebase.firestore.Firestore) {}

  public add = async (userId: string, { title, type, date }: Occasion) => {
    if (!date) return;
    const occasionRef = this.store.collection('users').doc(userId).collection('occasions').doc();

    const userRef = this.store.collection('users').doc(userId);
    const increment = firebase.firestore.FieldValue.increment(1);

    const batch = this.store.batch();
    batch.set(occasionRef, {
      title,
      type,
      group: date.format('MM_DD'),
      date: firebase.firestore.Timestamp.fromDate(date.toDate()),
    });
    batch.set(userRef, { totalOccasions: increment }, { merge: true });
    await batch.commit();
  };

  public remove = async (userId: string, occasionId: string) => {
    const occasionRef = this.store.collection('users').doc(userId).collection('occasions').doc(occasionId);

    const userRef = this.store.collection('users').doc(userId);
    const increment = firebase.firestore.FieldValue.increment(-1);

    const batch = this.store.batch();
    batch.delete(occasionRef);
    batch.set(userRef, { totalOccasions: increment }, { merge: true });
    await batch.commit();
  };

  public fetchAll = async (userId: string, limit = 20) => {
    const { docs } = await this.store
      .collection('users')
      .doc(userId)
      .collection('occasions')
      .orderBy('group')
      .limit(limit)
      .get();

    return docs.map((doc) => {
      const occasion = doc.data();
      occasion.id = doc.id;
      occasion.date = moment(occasion.date.seconds * 1000);
      occasion.dateFormatted = occasion.date.format('DD MMM YYYY');
      return occasion as Occasion;
    });
  };
}
