import React, { FC, useEffect, useCallback, ChangeEvent } from 'react';
import useFormFields from '@usereact/use-form-fields';
import moment, { Moment } from 'moment';
import 'twin.macro';

type DateInput = { onSet: (d: Moment | null) => void; resetRef: Function };
export const DateInput: FC<DateInput> = ({ onSet, resetRef }) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const { values, fields, setValue } = useFormFields({ day: '', month: '', year: '' });

  const onMonthChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setValue('month', e.target.value);
    },
    [setValue],
  );

  useEffect(() => {
    if (!values.day || !values.month || !values.year) onSet(null);
    else onSet(moment(`${values.day} ${values.month} ${values.year}`, 'D MMM YYYY'));
  }, [values.day, values.month, values.year, onSet]);

  const restFn = () => {
    setValue('day', '');
    setValue('month', '');
    setValue('year', '');
  };
  resetRef(restFn);

  return (
    <div tw="flex">
      <div tw="w-1/3 mr-3">
        <input
          type="text"
          maxLength={2}
          tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
          placeholder="Day"
          {...fields.day}
        />
      </div>
      <div tw="w-1/3">
        <select
          tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full appearance-none"
          className={values.month === '' ? 'placeholder' : ''}
          onChange={onMonthChange}
          value={values.month}
        >
          <option value="" disabled>
            Month
          </option>
          {months.map((m) => (
            <option value={m} key={m}>
              {m}
            </option>
          ))}
        </select>
      </div>
      <div tw="w-1/3 ml-3">
        <input
          type="text"
          maxLength={4}
          tw="p-3 placeholder-gray-400 border border-gray-400 rounded w-full"
          placeholder="Year"
          {...fields.year}
        />
      </div>
    </div>
  );
};
