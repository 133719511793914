import React, { FC } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { ToastProvider } from 'react-toast-notifications';

import { AddTripPage } from 'pages/app/trips/add-trip.page';
import { TripsPage } from 'pages/app/trips/trips.page';

import { AccountSettingsPage } from 'pages/app/settings/account-settings.page';
import { NotificationSettingsPage } from 'pages/app/settings/notification-settings.page';
import { OccasionsPage } from 'pages/app/occasions/occasions.page';
import { ProfileSettingsPage } from 'pages/app/settings/profile-settings.page';
import { SecuritySettingsPage } from 'pages/app/settings/security-settings.page';
import { SettingsPage } from 'pages/app/settings/settings.page';
import { StatsPage } from 'pages/app/stats/stats.page';

import { LoginPage } from 'pages/login/login.page';
import { ResetPage } from 'pages/login/reset.page';
import { ProfilePage } from 'pages/login/profile.page';
import { SignupPage } from 'pages/login/signup.page';

import { AboutPage } from 'pages/marketing/about.page';
import { HomePage } from 'pages/marketing/home.page';
import { NotFoundPage } from 'pages/marketing/not-found.page';
import { PrivacyPage } from 'pages/marketing/privacy.page';
import { TermsPage } from 'pages/marketing/terms.page';

import { AuthProvider } from 'auth.provider';
import { AuthenticatedRoute } from 'authenticated.route';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

const RedirectToHome: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  resetErrorBoundary();

  return <Redirect to="/" />;
};

export const AppRouter = () => {
  return (
    <Router>
      <ErrorBoundary FallbackComponent={RedirectToHome}>
        <ScrollMemory />
        <AuthProvider>
          <ToastProvider>
            <ExpaTrackRoutes />
          </ToastProvider>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
};

const ExpaTrackRoutes = () => (
  <Switch>
    <Route exact path="/home" component={HomePage} />
    <Route exact path="/about" component={AboutPage} />

    <Route exact path="/terms" component={TermsPage} />
    <Route exact path="/privacy" component={PrivacyPage} />

    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/signup" component={SignupPage} />
    <Route exact path="/profile" component={ProfilePage} />
    <Route exact path="/reset" component={ResetPage} />

    <AuthenticatedRoute path="/trips/add" component={AddTripPage} />
    <AuthenticatedRoute path="/trips" component={TripsPage} />

    <AuthenticatedRoute path="/occasions" component={OccasionsPage} />
    <AuthenticatedRoute path="/stats" component={StatsPage} />

    <AuthenticatedRoute path="/settings/account" component={AccountSettingsPage} />
    <AuthenticatedRoute path="/settings/profile" component={ProfileSettingsPage} />
    <AuthenticatedRoute path="/settings/notifications" component={NotificationSettingsPage} />
    <AuthenticatedRoute path="/settings/security" component={SecuritySettingsPage} />
    <AuthenticatedRoute path="/settings" component={SettingsPage} />

    <Redirect exact from="/" to="/home" />
    <Route render={NotFoundPage} />
  </Switch>
);
