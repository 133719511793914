import React, { FC } from 'react';
import 'twin.macro';

import { ColorCard } from 'pages/shared-components/color-card';

type SettingsCard = { color: string; icon: React.ReactNode; text: string; title: string };

export const SettingsCard: FC<SettingsCard> = ({ color, icon, text, title }) => {
  return (
    <ColorCard color={color}>
      <div tw="p-6">
        <div tw="flex items-center mb-4">
          <div tw="bg-opacity-10 rounded p-3 mr-4" className={`bg-${color}`}>
            <div tw="w-6">{icon}</div>
          </div>
          <div tw="font-semibold">{title}</div>
        </div>
        <div>{text}</div>
      </div>
    </ColorCard>
  );
};
