import React, { FC } from 'react';
import 'twin.macro';

import { MarketingLayout } from './marketing-layout';

export const PrivacyPage = () => {
  return (
    <MarketingLayout title="Privacy policy" subtitle="Effective: 01 May 2020">
      <section tw="py-10 lg:py-20 bg-gray-200">
        <div className="container" tw="mx-auto px-4">
          <div tw="flex flex-wrap justify-center text-justify px-4">
            <PrivacySection title="Introduction">
              <div tw="mt-5">
                This privacy policy (“Policy”) describes how ExpaTrack (“ExpaTrack” or “us” or “we”) collect, use, and
                share the information you provide to us or we gather from you while you access or use expatrack.com (the
                “Site”), ExpaTrack mobile or PWA application (the “App”), and related services that link to this Policy
                (together, “ExpaTrack Services”).
              </div>
              <div tw="mt-5">
                We may also supplement this Policy with other privacy notices in ExpaTrack Services. This Policy applies
                only to information collected by us through ExpaTrack Services and does not apply to services that link
                to other privacy policies, including any third-party websites or services you connect to from our
                ExpaTrack Services, such as social-networking platforms or connected third-party applications. ExpaTrack
                is not responsible for the privacy practices or content of such third parties. We encourage you to read
                the privacy statements of those third parties.
              </div>
              <div tw="mt-5">
                We collect and process personal information about you with your consent and/or as necessary to provide
                the products you use, operate our business, meet our contractual and legal obligations, protect the
                security of our systems and our customers, or fulfill other legitimate interests.
              </div>
            </PrivacySection>

            <PrivacySection title="Personal data collected">
              <div tw="mt-5">
                As used in this Policy, “Personal Data” means information relating to you as an identified or
                identifiable individual. You provide Personal Data directly into ExpaTrack Services. For example, as
                part of activating an account with ExpaTrack, we collect your name, email address and password, and home
                airport or country. If you create the account using one of the available social networking or
                third-party platform connection options (a “social sign-in” like Google or Facebook), we will collect
                your associated email address and related profile information from the social sign-in provider. You may
                also provide us with additional email addresses for purposes of collecting Trip information and to
                ensure that you retain access to your account even if you no longer have access to your primary email
                address.
              </div>
              <div tw="mt-5">
                To take full advantage of features and functionality of ExpaTrack Services, you may also provide us with
                additional information for your profile, such as your travel preferences. And you may input emergency
                contact details and government-issued identification numbers needed for travel, such as your passport or
                driver license number. This information helps you to keep your Trip information in one place. If you
                wish to receive alerts via text messages, you must provide your mobile phone number. If you purchase
                additional ExpaTrack Services, such as upgrading your account to ExpaTrack Pro, we will collect certain
                billing and payment information. We process any payments using third parties, and we would retain only
                the last four digits of your credit card number and expiration date.
              </div>
              <div tw="mt-5">
                We may also collect Personal Data from other sources and combine that with Personal Data we collect from
                you through ExpaTrack Services, as described below.
              </div>
            </PrivacySection>

            <PrivacySection title="Third-party connected services">
              <div tw="mt-5">
                The ExpaTrack Services also enable you to connect your ExpaTrack Services account with third-party
                applications and service providers. You can find information about many of these applications within the
                “Connected Apps” portion of the Site, and third-party applications that connect to ExpaTrack Services
                Services will usually indicate that they connect to ExpaTrack Services. These connected third-party may
                enable Personal Data about you to be transferred into ExpaTrack Services.
              </div>
              <div tw="mt-5">
                In other cases, Personal Data may be transferred to and from a third-party service so you can use
                ExpaTrack Services with a third-party application you have chosen. Your use of any third-party
                applications or third-party services is subject to the “Terms of use” you have agreed to with such third
                parties, including third-party privacy notices, and ExpaTrack is not responsible for the quality or
                accuracy of third-party data available to ExpaTrack Services from third parties or how such third
                parties may use Personal Data.
              </div>
            </PrivacySection>

            <PrivacySection title="Third-party social sign-ins">
              <div tw="mt-5">
                You can sign in to our ExpaTrack Services using third-party social sign-ins (e.g., Google, or Facebook).
                These social sign-in providers will share certain Personal Data with us as described in their sign-in
                notices or in their privacy policies, which might include information such as your name, profile
                picture, age range, gender, and other public information. We encourage you to review prior to signing in
                through the applicable service.
              </div>
            </PrivacySection>

            <PrivacySection title="Cookies, analytics, and tracking">
              <div tw="mt-5">
                When you use ExpaTrack Services through the Site, some information such as Internet protocol (IP)
                address, device or browser information, logs, or clickstream information is automatically collected
                about your usage and activity to address technical support issues and understand how you use the Site.
                ExpaTrack may use certain tracking technologies such as cookies, web beacons, or third-party analytics
                tools to obtain such information. Our App utilizes tracking technologies from third-party service
                providers we have engaged to provide services on our behalf. In some cases, these are analytics
                providers that provide services such as application analytics and crash reporting. In other cases, these
                are advertising or campaign measurement providers. These providers may also automatically collect some
                of the information described above, including, for example, usage information, IP address, access times,
                browser type and language, device type, device identifiers, and Wi-Fi information. These providers may
                also automatically collect the above information about you through the App and on other sites and
                services, including personally identifiable information about your online activities over time and
                across different websites, devices, online services, and applications when you use our App. Some third
                parties help us and others associate your activities across the browsers and devices you use, or that
                your household uses, for retargeting, cross-device advertising, analytics, and measurement purposes. The
                third-party analytics and advertising providers we use include:{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  tw="text-primary"
                >
                  Google Analytics
                </a>
                . Some third-party providers allow you choices about their collection or use of your information. Some
                of these providers may also be members of the Network Advertising Initiative (NAI) or Digital
                Advertising Alliance (DAA), which each provide a simple way to opt out of ad targeting from
                participating companies.
              </div>
            </PrivacySection>

            <PrivacySection title="Contacts">
              <div tw="mt-5">
                From the Site, you can access and import contacts from your other online address books, like Google,
                Yahoo, and Outlook. If you would like us to invite another individual to join ExpaTrack Services, you
                may provide us their email address so that we may send them an invitation on your behalf to visit the
                ExpaTrack Services. When using the App, the App may also access your device contacts if you enable those
                permissions.
              </div>
            </PrivacySection>

            <PrivacySection title="Location data">
              <div tw="mt-5">
                Certain features of ExpaTrack Services may collect precise geolocation data, including through GPS
                (global positioning systems), IP address, and other location-based technologies. Additional information
                about these features is provided below, along with the settings screens for the features in the App.
              </div>
            </PrivacySection>

            <PrivacySection title="How personal data is used">
              <div tw="mt-5">
                ExpaTrack will use Personal Data as described in this Policy and as disclosed to you in ExpaTrack
                Services, which include for the following purposes:
                <ul tw="list-disc list-outside pl-5">
                  <li tw="mt-4">
                    Creating and maintaining your trips and your profile and otherwise providing, operating, hosting,
                    maintaining, connecting, and improving ExpaTrack Services.
                  </li>
                  <li tw="mt-2">Responding to your comments and questions and providing customer service.</li>
                  <li tw="mt-2">
                    Training and operating automated systems that recognize and extract travel information from emails.
                  </li>
                  <li tw="mt-2">
                    Updating you on new features and functionality of ExpaTrack Services, as well as other news and
                    information about products and services offered by ExpaTrack, other affiliated companies, and our
                    selected partners.
                  </li>
                  <li tw="mt-2">
                    Providing you information via email or another form of electronic notification related to your use,
                    requests, and purchases, such as transaction confirmations, invoices, technical notices, and other
                    notices about security, privacy, and administrative issues relating to your use of ExpaTrack
                    Services.
                  </li>
                  <li tw="mt-2">
                    Personalizing your ExpaTrack Services experience, content, marketing, and recommendations, including
                    to target content and services to more closely match your interests or location.
                  </li>
                  <li tw="mt-2">
                    Creating anonymized data sets from Personal Data to improve our products and services.
                  </li>
                  <li tw="mt-2">Enforcing our “Terms of use” or protecting our business, partners, or users.</li>
                  <li tw="mt-2">
                    Protecting against, investigating, and deterring fraudulent, unauthorized, infringing, or illegal
                    activity.
                  </li>
                </ul>
              </div>
            </PrivacySection>

            <PrivacySection title="Choices you have regarding personal data">
              <div tw="mt-5">
                <div tw="font-bold mb-2">Profile information</div>
                Through the settings page within the Site and the App, you can update profile information associated
                with your account at any time, such as contact and payment information, travel preferences, privacy and
                sharing settings, and alerts and connected applications. You may choose to display certain information
                you give us in your public profile. Our Site may offer publicly accessible blogs or community forums.
                You should be aware that any information you choose to share in these areas may be read, collected, and
                used by others.
              </div>
              <div tw="mt-5">
                If you otherwise wish to ask for access, correction, or deletion of any of your information held by us,
                or a change in the way we use your information, please contact us via{' '}
                <a href="mailto:support@expatrack.com" tw="text-primary">
                  email
                </a>
                . We reserve the right to charge you a fee, as permitted by applicable law, and may decline requests
                that are unreasonable, prohibited by law, or are not required to be honored by applicable law.
              </div>
              <div tw="mt-5">
                <div tw="font-bold mb-2">Location data</div>
                ExpaTrack Services provide features that require your precise location and the use of location services
                on your mobile device through the App. Some of these features may be available only to users of the
                premium “Pro” version of the ExpaTrack Services. You may enable or disable these ExpaTrack Services
                within the App and the privacy settings in your mobile device.
              </div>
              <div tw="mt-5">
                <div tw="font-bold mb-2">Connections with other services</div>
                Other third-party services you have chosen to connect with ExpaTrack Services may be enabled or disabled
                through selections in the Site or the App. You usually can also enable or disable connections through
                the third-party services themselves. Certain third-party services we may feature through our ExpaTrack
                Services give you social-media options to interact with buttons or widgets that let you “like”
                information or post information about your activities publicly from ExpaTrack Services to third-party
                sites and platforms or privately within your network. By connecting your ExpaTrack Services account with
                third-party services, you enable third-party services to access ExpaTrack Services API and gain access
                to your Trip information, as well as your profile information. Their use of this information is governed
                by their user terms with you and their privacy policies.
              </div>
              <div tw="mt-5">
                <div tw="font-bold mb-2">Email and marketing choices</div>
                You can manage your email preferences through the selections within the Site or the App by updating your
                email subscription settings. You can also unsubscribe from promotional emails by: (1) following the
                unsubscribe instructions in our emails; or (2){' '}
                <a href="mailto:support@expatrack.com" tw="text-primary">
                  emailing us
                </a>
                . You may update your email preferences from time to time. Please note that even if you unsubscribe from
                promotional email messages, we may still need to contact you with important transactional information
                related to your account and your use of the Service. For example, even if you have unsubscribed from our
                email messages, we will still send you emails relating to security, billing, or account services such as
                password reset.
              </div>
              <div tw="mt-5">
                <div tw="font-bold mb-2">Push and text message alerts</div>
                You may manage the push message alerts regarding flight delays, gate changes, and other alert
                information specific to your Trip sent by ExpaTrack Services to your mobile device through the settings
                page in the Site or within the App. Similarly, you may manage text message alerts through the settings
                page in the Site or within the App, and you may always opt out of text message alerts by replying STOP
                to any text message sent by ExpaTrack.
              </div>
              <div tw="mt-5">
                <div tw="font-bold mb-2">Cookies, analytics, and tracking</div>
                You also have choices regarding some of the technologies used to track your use of ExpaTrack Services.
                Note that the settings are limited to the particular browser installed on a particular device. So if you
                use ExpaTrack Services with different browsers or different devices, including a mobile device, you may
                have to disable the tracking cookies in the browsers of all relevant devices. It may not always be
                possible to disable cookies in the App, and disabling cookies on the Site will not disable tracking
                technologies on the App. We may not recognize or respond to every type of “do not track” signal or other
                mechanisms that provide consumers the ability to exercise choice regarding the collection of Personal
                Data about an individual consumer’s online activities over time and across third-party websites or
                online services, but we give you certain choices about how we collect Personal Data as described in this
                Policy. For more information on how we collect Personal Data for these purposes, see the “Cookies,
                Analytics and Tracking” section above.
              </div>
            </PrivacySection>

            <PrivacySection title="How ExpaTrack shares personal data">
              <div tw="mt-5">
                ExpaTrack Services may share your Personal Data as described in this Policy and as follows:
                <ul tw="list-disc list-outside pl-5">
                  <li tw="mt-4">With your consent.</li>
                  <li tw="mt-2">
                    With third parties to the extent that you have enabled a connection between your ExpaTrack Services
                    account and a third-party service.
                  </li>
                  <li tw="mt-2">
                    With other ExpaTrack users or the public through your public profile in accordance with your privacy
                    settings, if you share trips, and if you grant another ExpaTrack user access to manage or view your
                    travel plans.
                  </li>
                  <li tw="mt-2">
                    When we believe it is appropriate to investigate, prevent, or take action regarding illegal or
                    suspected illegal activities; to protect and defend the rights, property, or safety of ExpaTrack,
                    our users, or others; and in connection with the enforcement of our terms and contracts.
                  </li>
                  <li tw="mt-2">
                    In connection with a corporate transaction, such as a divestiture, merger, acquisition,
                    consolidation, or asset sale, or in the unlikely event of bankruptcy. We may assign or transfer your
                    information as part of the transaction.
                  </li>
                  <li tw="mt-2">
                    With third-party vendors, consultants, and other service providers that are working on our behalf
                    and need access to your information to provide specific services to us as part of ExpaTrack
                    Services, such as credit card processing companies, or hosting, email messaging, or SMS providers.
                  </li>
                  <li tw="mt-2">In an aggregated form that does not directly identify you.</li>
                  <li tw="mt-2">
                    As required by law and when we believe that disclosure is necessary to protect our rights and/or
                    comply with a judicial proceeding, court order, or legal process served to us.
                  </li>
                </ul>
              </div>
            </PrivacySection>

            <PrivacySection title="How personal data may be transferred to the United States">
              <div tw="mt-5">
                ExpaTrack Services operate from data centers in multiple locations globally, including in the United
                States. Using ExpaTrack Services may result in the transfer, use, processing, or storage of Personal
                Data in the United States or any other country where ExpaTrack operates or maintains facilities or
                service centers, including jurisdictions that may not have data privacy laws that provide protections
                equivalent to those provided in your home country. ExpaTrack takes steps designed to ensure that the
                Personal Data is processed according to applicable law wherever the data is located and the provisions
                of our agreements, including any intercompany data-processing agreements among corporate affiliates.
              </div>
              <div tw="mt-5">
                By providing your information to us through your use of ExpaTrack Services, you agree to that transfer,
                storage, and processing of your Personal Data in the United States.
              </div>
            </PrivacySection>

            <PrivacySection title="Security and retention of personal data">
              <div tw="mt-5">
                We maintain data handling and storage practices and procedures that are designed to promote the
                integrity and confidentiality of the personally identifiable information. No method of transmission over
                the Internet or method of electronic storage is completely secure, however. Therefore, while we strive
                to use commercially acceptable means to protect your personal information, we cannot guarantee its
                absolute security.
              </div>
              <div tw="mt-5">
                We retain your Personal Data for as long as necessary to provide ExpaTrack Services and for other
                essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our
                agreements. Because these needs can vary for different data types in the context of different services,
                actual retention periods can vary significantly. The criteria used to determine retention include (1)
                the length of time Personal Data is needed to provide the applicable services (such as maintaining and
                improving the performance of ExpaTrack Services, enabling system security measures, and maintaining
                appropriate business and financial records); (2) if users have provided, created, or maintained Personal
                Data with the expectation that we will retain it until they affirmatively remove it (in which case we
                may maintain the data until actively deleted by the user); (3) whether the Personal Data is of a
                sensitive type (a shortened retention time may be appropriate); and (4) whether we are subject to a
                legal, contractual, or similar obligation to retain the data (such as mandatory data retention laws in
                the applicable jurisdiction, government orders to preserve data, or data that must be retained for the
                purposes of litigation).
              </div>
            </PrivacySection>

            <PrivacySection title="Updates and questions">
              <div tw="mt-5">
                We may update this Policy from time to time without notice to you other than posting the revised policy
                on ExpaTrack Services or by providing such notice about or obtaining consent to changes as may be
                required by applicable law. Some features listed here might be part of an upcoming product roadmap and
                may not be a feature that you may have used before. If we change our Policy, we will post those changes
                to this Policy and change the effective date above. We encourage you to periodically review this page
                for the latest information on our privacy practices.
              </div>
              <div tw="mt-5">
                If you have any questions about this Policy, or to file any complaint regarding this policy, please
                contact us via{' '}
                <a href="mailto:support@expatrack.com" tw="text-primary">
                  email
                </a>
                . We will investigate and attempt to resolve complaints and disputes regarding the collection, use, and
                disclosure of personal information by referencing the privacy principles stated in this Policy.
              </div>
            </PrivacySection>

            <PrivacySection title="Translations of this policy">
              <div tw="mt-5">
                This Policy is written in the English language. If any translated versions of this Policy conflict with
                the English language version, the English language version of this Policy shall control.
              </div>
            </PrivacySection>
          </div>
        </div>
      </section>
    </MarketingLayout>
  );
};

const PrivacySection: FC<{ title: string }> = ({ title, children }) => (
  <div tw="bg-white rounded border border-gray-400 leading-relaxed mb-8">
    <div tw="flex-auto p-6 lg:p-10">
      <div tw="text-primary font-bold mb-5">{title}</div>
      <div tw="text-sm">{children}</div>
    </div>
  </div>
);
