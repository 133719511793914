import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import 'twin.macro';

import { Logo } from 'pages/shared-components/logo';
import { useUser } from 'auth.provider';

const clsHeaderLinks = 'flex flex-wrap justify-center mt-6 md:mt-0 w-full md:w-auto';

export const Header = () => {
  const user = useUser();
  let headerLinks;

  if (user.isLoggedIn && user.hasProfile) {
    headerLinks = (
      <div className={clsHeaderLinks}>
        <div tw="flex items-center text-center px-4 py-3">Welcome, {user.name}</div>
        <HeaderLink text="Manage trips" to="/trips" isButton={true} />
      </div>
    );
  } else if (user.isLoggedIn && !user.hasProfile) {
    headerLinks = (
      <div className={clsHeaderLinks}>
        <div tw="flex items-center text-center px-4 py-3">Welcome, {user.email}</div>
        <HeaderLink text="Complete signup" to="/profile" isButton={true} />
      </div>
    );
  } else {
    headerLinks = (
      <div className={clsHeaderLinks}>
        <HeaderLink text="Login" to="/login" isButton={false} />
        <HeaderLink text="Signup for free" to="/signup" isButton={true} />
      </div>
    );
  }

  return (
    <div>
      <div className="container" tw="px-4 py-8 mx-auto flex flex-wrap items-center justify-center md:justify-between">
        <Logo />
        {headerLinks}
      </div>
    </div>
  );
};

const HeaderLink: FC<{ to: string; text: string; isButton?: boolean }> = ({ to, text, isButton = false }) => (
  <div tw="flex items-center">
    <Link
      to={to}
      tw="flex items-center text-center px-6 py-3 rounded mt-4 sm:mt-0"
      className={`${isButton ? 'text-white bg-primary ml-3 mr-3 sm:mr-0' : 'text-dark bg-gray-200'}`}
    >
      {text}
    </Link>
  </div>
);
