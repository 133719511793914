import React, { FC, ReactNode } from 'react';
import 'twin.macro';

type Banner = {
  title: string;
  subtitle: string | ReactNode;
  isTall: boolean;
};

const styleHeight128 = { height: '128px' };

export const Banner: FC<Banner> = ({ title, subtitle, isTall = false }) => {
  return (
    <div
      tw="relative flex content-center items-center justify-center min-h-0"
      className={`${isTall ? 'pt-10 md:pt-24 pb-32 md:pb-64' : 'pt-8 md:pt-10 pb-16 md:pb-20'}`}
    >
      <div className="container" tw="relative mx-auto">
        <div tw="items-center flex flex-wrap">
          <div tw="px-4 mx-auto text-center">
            <h1 tw="font-display text-dark text-3xl md:text-5xl mb-5">{title}</h1>
            <h3 tw="font-light text-primary text-xl md:text-2xl">{subtitle}</h3>
          </div>
        </div>
      </div>
      {isTall ? <div tw="top-auto bottom-0 left-0 right-0 w-full absolute bg-white" style={styleHeight128}></div> : ''}
    </div>
  );
};
