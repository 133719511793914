import { useState, useEffect, useCallback } from 'react';
import { useDatastore, useUser } from 'auth.provider';
import { Occasion as OccasionType, OccasionService } from 'services/occasion.service';

import moment from 'moment';
import _groupBy from 'lodash.groupby';

export type Occasion = OccasionType;

export const useOccasions = () => {
  const user = useUser();
  const store = useDatastore();

  const [occasions, setOccasions] = useState<{ [key: string]: Occasion[] }>();
  const [isLoading, setIsLoading] = useState(false);

  const getGroupTitle = (key: string) => {
    return moment(key, 'MM_DD').format('DD MMMM');
  };

  const fetchOccasions = useCallback(async () => {
    const service = new OccasionService(store);

    setIsLoading(true);
    const o = await service.fetchAll(user.id);
    if (o) setOccasions(_groupBy(o, 'group'));
    setIsLoading(false);
  }, [store, user.id]);

  useEffect(() => {
    fetchOccasions();
  }, [fetchOccasions]);

  return { occasions, isLoading, setIsLoading, fetchOccasions, getGroupTitle };
};
